import { error as logError } from 'components/vendor/perform/core';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import globalPubsub from 'pubsub.js';

const FILTER_TOTAL = 'total';
const FILTER_H2H = 'head-to-head';
const H2H_COMPARE_COUNT = 2;
const GROUP_ID_ALL = 'all';

/**
 * Competition tables widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 * @param {string} settings.url - Ajax request url
 * @param {Object} settings.asyncRequestParams - Parameters passed to asynchronous request
 * @param {boolean} settings.initialized - True if component was initialized in synchronous version
 */
export default function (context, settings) {
  const $context = $(context);
  const blockName = 'p0c-competition-tables';
  const blockSelector = `.${blockName}`;
  const tableClass = `${blockName}__table`;
  const tableSelector = `.${tableClass}`;
  const eventNamespace = pubsubNamespace(context);
  const $tableContainer = $context.find(`${tableSelector}-container`);
  const filterClass = `${blockName}__filter`;
  const filterHiddenClass = `${filterClass}--hidden`;
  const typeFilterSelector = `.${filterClass}--type`;
  const stageFilterSelector = `.${filterClass}--stage`;
  const h2hBlockName = `${blockName}__h2h`;
  const h2hSelector = `.${h2hBlockName}`;
  const h2hCancelSelector = `${h2hSelector}-button--cancel`;
  const h2hCompareSelector = `${h2hSelector}-button--compare`;
  const h2hHiddenClass = `${h2hBlockName}-footer--hidden`;
  const h2hContainerClass = `${h2hBlockName}-container`;
  const h2hContainerSelector = `.${h2hContainerClass}`;
  const h2hContainerHiddenClass = `${h2hContainerClass}--hidden`;
  const h2hCheckboxSelector = `${blockSelector}__h2h-checkbox input`;
  const h2hNoTeam = $context.data('h2hNoTeam');
  const h2hTeamSelector = `${h2hSelector}-team`;
  const h2hTeamSelectors = [
    `${h2hTeamSelector}--first`,
    `${h2hTeamSelector}--second`,
  ];
  const h2hRowSelector = `${tableSelector}--head-to-head tbody tr`;
  const showMoreClass = `${blockName}__show-more`;
  const showMoreSelector = `.${showMoreClass}`;
  const showMoreButtonClass = `${showMoreClass}-button`;
  const showMoreButtonSelector = `.${showMoreButtonClass}`;
  const legendClass = `${blockName}__legend`;
  const legendSelector = `.${legendClass}`;
  const asyncRequestParams = settings.asyncRequestParams;
  const pubsub = $context.data('pubsub') || globalPubsub;
  const ajaxInstances = {};
  const tableContainers = {};
  let seasonId = asyncRequestParams.seasonId;
  let prevSeasonId = seasonId;
  let stageId = asyncRequestParams.stageId;
  let initialized = settings.initialized;
  let globalFilter = asyncRequestParams.filterType || FILTER_TOTAL;
  let h2hTeams;
  let filterTypes;
  let $stageFilter;

  /**
   * Mark team row as selected
   * @param {jQuery} $teamRow - jQuery team row element
   */
  function toggleTeam($teamRow) {
    const team = parseTeamInfo($teamRow);
    const groupId = $teamRow.closest('[data-group-id]').data('groupId');
    const $checkbox = $teamRow.find(h2hCheckboxSelector);

    if (!$checkbox.prop('checked')) {
      h2hSetTeam(groupId, team);
    } else {
      h2hUnsetTeam(groupId, team);
    }
  }

  /**
   * Parse team info from row HTML element data attributes
   * @param {jQuery} $teamRow - jQuery row element
   * @returns {Object} - parsed team info
   */
  function parseTeamInfo($teamRow) {
    const teamInfo = $teamRow.data();
    const parsed = {};

    Object.keys(teamInfo).forEach((key) => {
      if (key.indexOf('team') === 0) {
        const newKey = key.replace('team', '');
        parsed[newKey.charAt(0).toLowerCase() + newKey.slice(1)] = teamInfo[key];
      }
    });

    return parsed;
  }

  /**
   * Update H2H checkboxes state
   * @param {string} groupId - group id
   */
  function h2hUpdateCheckboxesState(groupId) {
    const groupTeams = h2hTeams[groupId];
    const $rows = tableContainers[groupId].find(h2hRowSelector);
    const firstTeamId = groupTeams[0] ? groupTeams[0].id : null;
    const secondTeamId = groupTeams[1] ? groupTeams[1].id : null;

    $rows.each(function rowsIterator() {
      const $row = $(this);
      const $checkbox = $row.find(h2hCheckboxSelector);
      const teamId = $row.data('teamId');
      const isSelected = (teamId === firstTeamId || teamId === secondTeamId);

      $checkbox.prop('checked', isSelected);
      $row.toggleClass(`${blockName}__row--active`, isSelected);
    });
  }

  /**
   * Show or hide head to head buttons
   * @param {string} groupId - group id
   */
  function h2hUpdateButtonState(groupId) {
    const groupTeams = h2hTeams[groupId];
    const showButtons = groupTeams[0] && groupTeams[1];
    tableContainers[groupId]
      .find(`${h2hSelector}-footer`)
      .toggleClass(h2hHiddenClass, !showButtons);
  }

  /**
   * Unset first and second selected team
   * @param {string} groupId - group id
   * @param {boolean} [publish=true] - publish pubsub h2h/reset event
   */
  function h2hReset(groupId, publish = true) {
    h2hTeams[groupId] = [null, null];
    tableContainers[groupId]
      .find(`${h2hSelector}-team`)
      .text(h2hNoTeam)
      .attr('data-abbreviation', h2hNoTeam);

    h2hUpdateButtonState(groupId);
    h2hUpdateCheckboxesState(groupId);

    if (publish) {
      pubsub.publish(`${eventNamespace}/h2h/reset`, [context, groupId, h2hTeams[groupId]]);
    }
  }

  /**
   * Set team and calls update buttons
   * @param {string} groupId - group id
   * @param {Object} team - team to set
   */
  function h2hSetTeam(groupId, team) {
    const groupTeams = h2hTeams[groupId];
    const index = !groupTeams[0] ? 0 : 1;
    groupTeams[index] = team;
    tableContainers[groupId]
      .find(h2hTeamSelectors[index])
      .text(team.name)
      .attr('data-abbreviation', team.abbreviation);

    pubsub.publish(`${eventNamespace}/h2h/set-team`, [context, groupId, groupTeams]);

    h2hUpdateButtonState(groupId);
    h2hUpdateCheckboxesState(groupId);
  }

  /**
   * Unset team by team id
   * @param {string} groupId - group id
   * @param {Object} team - teams object
   */
  function h2hUnsetTeam(groupId, team) {
    const groupTeams = h2hTeams[groupId];
    for (let index = 0; index < H2H_COMPARE_COUNT; index++) {
      if (groupTeams[index] && groupTeams[index].id === team.id) {
        groupTeams[index] = null;
        tableContainers[groupId]
          .find(h2hTeamSelectors[index])
          .text(h2hNoTeam)
          .attr('data-abbreviation', h2hNoTeam);

        pubsub.publish(`${eventNamespace}/h2h/unset-team`, [context, groupId, groupTeams]);

        h2hUpdateButtonState(groupId);
        h2hUpdateCheckboxesState(groupId);
        break;
      }
    }
  }

  $context
    .on(
      'click',
      h2hRowSelector,
      function rowClickHandler(ev) {
        const $clickedRow = $(this).closest(h2hRowSelector);
        ev.preventDefault();

        toggleTeam($clickedRow);
      }
    )
    .on(
      'click',
      showMoreSelector,
      () => {
        $context
          .toggleClass(`${blockName}--snippet-expand`);
        $context
          .find(showMoreButtonSelector)
          .toggleClass(`${showMoreButtonClass}--hidden`);
        $context
          .find(legendSelector)
          .toggleClass(`${legendClass}--hidden`);
      }
    )
    .on('click', h2hCancelSelector, function h2hCancelHandler() {
      const $this = $(this).closest('[data-group-id]');
      const disivionId = $this.data('groupId');
      h2hReset(disivionId);
      updateTypeFilter(FILTER_TOTAL, disivionId);
      $this.find(typeFilterSelector).val(FILTER_TOTAL);
    })
    .on('click', h2hCompareSelector, function h2hCompareHandler() {
      const $this = $(this).closest('[data-group-id]');
      const disivionId = $this.data('groupId');
      const groupTeams = h2hTeams[disivionId];

      if (!groupTeams[0] || !groupTeams[1]) {
        return;
      }

      window.location.href = settings.headToHeadTemplateUrl
        .replace('var-first-team-id-var', groupTeams[0].id)
        .replace('var-second-team-id-var', groupTeams[1].id)
      ;
    })
  ;

  /**
   * Get data for table using AJAX
   * @param {string|null} [groupId=null] - group id to load
   * @returns {Promise} - AJAX request promise
   */
  function getTableData(groupId = null) {
    if (!initialized) {
      return null;
    }

    const data = JSON.parse(JSON.stringify(asyncRequestParams));
    const getDataDeferred = $.Deferred(); // eslint-disable-line new-cap

    cancelRequest(groupId);

    data.seasonId = seasonId;
    data.stageId = stageId;

    if (groupId) {
      data.filterType = filterTypes[groupId];
      data.groupId = groupId;
    } else {
      filterTypes = null;
      h2hTeams = null;
      groupId = GROUP_ID_ALL;
      data.filterType = globalFilter;
      data.groupId = null;
    }

    pubsub.publish(`${eventNamespace}/ajax/before`, [context, data, groupId]);

    ajaxInstances[groupId] = $.ajax({
      url: settings.url,
      data,
    })
      .done((response) => {
        if (response.status === 'success') {
          if (groupId !== GROUP_ID_ALL) {
            tableContainers[groupId].replaceWith(response.data.tables.html);
          } else {
            $tableContainer.html(response.data.tables.html);
          }

          init();

          if (prevSeasonId !== seasonId) {
            updateStages(response.data.stages.json);
            prevSeasonId = seasonId;
          }
          pubsub.publish(`${eventNamespace}/ajax/complete`, [context, response.data, groupId]);
          getDataDeferred.resolve(response.data, groupId);
        } else {
          logError(`Error: ${response.data}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, response.data, groupId]);
          getDataDeferred.reject(response.data, groupId);
        }
      })
      .fail((xhr, status, error) => {
        if (status !== 'abort') {
          logError(`Error: ${xhr}, ${status}, ${error}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, { xhr, status, error }, groupId]);
          getDataDeferred.reject(error, groupId);
        }
      })
      .always(() => {
        pubsub.publish(`${eventNamespace}/ajax/after`, [context, groupId]);
        if (data.filterType === 'total') {
          pubsub.publish(`${eventNamespace}/init-sorting`);
        }
        ajaxInstances[groupId] = null;
      })
    ;

    return getDataDeferred.promise();
  }

  /**
   * Cancel last request
   * @param {string|null} [groupId=null] - group id request to cancel
   */
  function cancelRequest(groupId = null) {
    if (!groupId) {
      Object.keys(ajaxInstances).forEach(id => cancelRequest(id));
    } else if (ajaxInstances[groupId]) {
      ajaxInstances[groupId].abort();
      ajaxInstances[groupId] = null;
      pubsub.publish(`${eventNamespace}/ajax/cancel`, [context, groupId]);
    }
  }

  /**
   * Update stages dropdown
   * @param {Object} stages - contains stages information for currrent season
   * @param {Array} stages.items - new stages filter values
   * @param {string} stages.selected - currently selected stage
   */
  function updateStages(stages) {
    if (!$stageFilter.length) {
      return;
    }

    const newOptions = Object.values(stages.items);
    $stageFilter.find('option').remove();

    newOptions.forEach((item) => {
      const $option = $('<option>')
        .attr('value', item.id)
        .text(item.name);
      $stageFilter.append($option);
    });

    if (newOptions.length && stages.selected) {
      $stageFilter.val(stages.selected);
    }

    $stageFilter.toggleClass(filterHiddenClass, newOptions.length === 0);
  }

  /**
   * Update type filter for selected group table
   * @param {string} newFilterType - new type filter
   * @param {string} groupId - group id
   */
  function updateTypeFilter(newFilterType, groupId) {
    let loadData = false;
    const prevFilterType = filterTypes[groupId];

    if (newFilterType === prevFilterType) {
      return;
    }

    if ((newFilterType === FILTER_H2H && prevFilterType === FILTER_TOTAL)
      || (newFilterType === FILTER_TOTAL && prevFilterType === FILTER_H2H)
    ) {
      cancelRequest(groupId);
    } else {
      loadData = true;
    }

    filterTypes[groupId] = newFilterType;

    h2hReset(groupId, false);

    if (loadData) {
      getTableData(groupId);
    } else {
      tableContainers[groupId]
        .find(h2hContainerSelector)
        .toggleClass(h2hContainerHiddenClass, newFilterType !== FILTER_H2H);
      tableContainers[groupId]
        .find(tableSelector)
        .removeClass(`${tableClass}--${prevFilterType}`);
      tableContainers[groupId]
        .find(tableSelector)
        .addClass(`${tableClass}--${newFilterType}`);
    }
  }

  /**
   * Update stage
   * @param {string} newStageId - new stage id
   */
  function updateStageId(newStageId) {
    if (stageId === newStageId) {
      return;
    }

    if ($stageFilter && $stageFilter.length) {
      $stageFilter.val(newStageId);
    }

    globalFilter = FILTER_TOTAL;
    stageId = newStageId;
    getTableData();
  }

  /**
   * Init table data
   */
  function init() {
    let setFilterTypes = false;
    let setH2HTeams = false;

    if (!filterTypes) {
      filterTypes = {};
      setFilterTypes = true;
    }

    if (!h2hTeams) {
      h2hTeams = {};
      setH2HTeams = true;
    }

    if ($stageFilter) {
      $stageFilter.off();
    }

    $stageFilter = $context.find(stageFilterSelector);
    $stageFilter.on('change', () => {
      updateStageId($stageFilter.val());
    });

    $context.find('[data-group-id]').each(function tablesIterator() {
      const $this = $(this);
      const groupId = $this.data('groupId');
      tableContainers[groupId] = $this;

      if (setFilterTypes) {
        filterTypes[groupId] = asyncRequestParams.filterType;
      }

      if (setH2HTeams) {
        h2hTeams[groupId] = [null, null];
      }
    });

    if (Object.keys(tableContainers).length === 1) {
      $context.find(`${blockSelector}__group-name`).remove();
    }
  }

  /**
   * Reload all tables
   */
  function reloadAllTables() {
    const uniqueFilters = Object
      .values(filterTypes)
      .filter((value, index, self) => self.indexOf(value) === index);
    const oneFilter = uniqueFilters.length <= 1;

    Object.keys(tableContainers).forEach((groupId) => {
      if (filterTypes[groupId] === FILTER_H2H) {
        filterTypes[groupId] = FILTER_TOTAL;
      }

      if (!oneFilter) {
        getTableData(groupId);
      }
    });

    if (oneFilter) {
      if (uniqueFilters.length === 1) {
        globalFilter = uniqueFilters[0];
      }

      getTableData();
    }
  }

  $context.on('change', typeFilterSelector, function typeFilterChange() {
    const $filter = $(this).closest(typeFilterSelector);
    const groupId = $filter.closest('[data-group-id]').data('groupId');

    updateTypeFilter($filter.val(), groupId);
  });

  pubsub.subscribe(
    `${eventNamespace}/season-change`,
    (eventContext, season) => {
      if (context !== eventContext || seasonId === season.id) {
        return;
      }

      prevSeasonId = seasonId;
      seasonId = season.id;
      globalFilter = FILTER_TOTAL;
      stageId = null;

      reloadAllTables();
    }
  );

  pubsub.subscribe(
    `${eventNamespace}/filter-type-change`,
    (eventContext, newFilterType, groupId = null) => {
      if (context !== eventContext) {
        return;
      }

      if (groupId) {
        tableContainers[groupId]
          .find(typeFilterSelector)
          .val(newFilterType);
        updateTypeFilter(newFilterType, groupId);
      } else {
        $(Object.values(tableContainers))
          .find(typeFilterSelector)
          .val(newFilterType);

        Object.keys(filterTypes).forEach(filterGroupId => {
          filterTypes[filterGroupId] = newFilterType;
        });

        reloadAllTables();
      }
    }
  );

  pubsub.subscribe(
    `${eventNamespace}/stage-change`,
    (eventContext, newStageId) => {
      if (context !== eventContext) {
        return;
      }

      updateStageId(newStageId);
    }
  );

  if (!initialized) {
    const initSubscription = pubsub.subscribe(
      `${eventNamespace}/init`,
      (eventContext) => {
        if (context !== eventContext) {
          return;
        }

        initialized = true;
        getTableData();
        pubsub.unsubscribe(initSubscription);
      }
    );
  } else {
    init();
    $(window).on('load', () => {
      $context.find(h2hCheckboxSelector).prop('checked', false);
      $context.find('[data-group-id]').each(function tablesIterator() {
        const $this = $(this);
        $this.find(typeFilterSelector).val(filterTypes[$this.data('groupId')]);
      });
    });
  }
}
