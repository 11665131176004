import Base from './base';

/**
 * Favourite filter
 */
export default class Favourite extends Base {
  /**
   * @inheritdoc
   */
  constructor() {
    super('favourite');
    this.favourites = {};
  }

  /**
   * @inheritdoc
   */
  apply(data) {
    return Object.keys(this.favourites)
      .map(key => [data[key], key])
      .filter(([entity]) => entity)
      .reduce((dict, [entity, key]) => {
        dict[key] = entity;
        return dict;
      }, {});
  }
}
