import DefaultMatchRow from './match-row/default';
import SoccerMatchRow from './match-row/soccer';
import TennisMatchRow from './match-row/tennis';
import Sport from '../../match/sport';

/**
 * Get match row template result depending on match row data
 * @param {Object} row - match row data
 * @param {Object} linker - linker
 * @param {Object} data - custom data
 * @returns {TemplateResult} - match row template result
 */
export default function factory(row, linker, data) {
  let matchRow;

  switch (row.data.sportType) {
    case Sport.SOCCER:
      matchRow = new SoccerMatchRow(row, linker, data);
      break;
    case Sport.TENNIS:
      matchRow = new TennisMatchRow(row, linker, data);
      break;
    default:
      matchRow = new DefaultMatchRow(row, linker, data);
      break;
  }

  return matchRow.render();
}
