import MatchPeriodId from '../soccer';

export default (periodId) => {
  switch (periodId) {
    case MatchPeriodId.FIRST_HALF:
    case MatchPeriodId.SECOND_HALF:
      return 45;
    case MatchPeriodId.FIRST_EXTRA_TIME:
    case MatchPeriodId.SECOND_EXTRA_TIME:
      return 15;
    default:
      return null;
  }
};
