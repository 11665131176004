import matchRow from './templates/match-row';
import competitionHeader from './templates/competition-header/default';
import tennisCompetitionHeader from './templates/competition-header/tennis';
import sportHeaderFactory from './templates/sport-header';
import dateHeader from './templates/date-header';
import { isSameDay } from '../utils';
import Sport from '../match/sport';

/**
 * Create match row definition
 * @param {Object} match - match object
 * @param {number} rowIndex - current match row index
 * @param {boolean} last - mark as last item
 * @returns {Object} - match row definition
 */
function createMatchRow(match, rowIndex, last) {
  return {
    id: `widget-livescore-match-row-${match.id}`,
    first: rowIndex === 0,
    even: rowIndex % 2 === 1,
    last,
    template: matchRow,
    data: match,
  };
}

/**
 * Create date header row definition
 * @param {Object} match - match data
 * @param {number} match.matchStartTime - match start time
 * @param {string} match.id - match id
 * @returns {Object} - date header row definition
 */
function createDateHeader({ matchStartTime, id }) {
  const formatedDate = new Date(matchStartTime);
  return {
    id: `widget-livescore-date-header-${id}`,
    template: dateHeader,
    data: formatedDate,
  };
}

/**
 * Append header if conditions are met
 * @param {Array} rows - match rows
 * @param {Object} match - match data object
 * @param {Date} currentMatchDay - current match day
 * @param {Date} selectedDate - selected date
 * @returns {Date} - current match day or previous match day
 */
function appendDateHeader(rows, match, currentMatchDay, selectedDate) {
  const matchDay = new Date(match.matchStartTime);
  matchDay.setHours(0, 0, 0, 0);

  if ((!currentMatchDay || !isSameDay(currentMatchDay, matchDay)) && matchDay > selectedDate) {
    rows.push(createDateHeader(match));
    return matchDay;
  }

  return currentMatchDay;
}

/**
 * Append sport specific header
 * @param {Array} rows - match rows
 * @param {Object} match - match data object
 * @param {string} match.sportType - match sport type
 * @param {string} match.id - match id
 * @param {string|null} [prevSportType=null] - previous sport type
 * @returns {string} - previous or new sport type
 */
function appendSportHeader(rows, { sportType, id }, prevSportType = null) {
  if (sportType === prevSportType) {
    return prevSportType;
  }

  const sportHeader = sportHeaderFactory(sportType);

  if (sportHeader) {
    rows.push({
      id: `widget-livescore-sport-header-${id}`,
      template: sportHeader,
    });
  }

  return sportType;
}

/**
 * Simple strategy
 * @param {Array} matches - matches list
 * @param {Array} rowWrappers - List of row wrappers
 * @param {Array} adsWrappers - List of ads wrappers
 * @param {Object} data - Custom data
 * @param {Date} data.selectedDate - Selected date
 * @returns {Array} - List of row wrappers with rows to render
 */
export function simple(matches, rowWrappers, adsWrappers, { selectedDate }) {
  let rowIndex = 0;
  let wrapperIndex = 0;
  let matchDay = null;
  let currentSportType = null;
  let rows = rowWrappers[wrapperIndex].rows;
  rowWrappers[wrapperIndex].visible = true;

  matches.forEach((match, index) => {
    const newMatchDay = appendDateHeader(rows, match, matchDay, selectedDate);

    if (matchDay !== newMatchDay) {
      matchDay = newMatchDay;
      rowIndex = 0;
    }

    if ((index - 7) % 15 === 0 && wrapperIndex < rowWrappers.length - 1) {
      wrapperIndex++;
      rows[rows.length - 1].last = true;
      rows = rowWrappers[wrapperIndex].rows;
      rowIndex = 0;
      currentSportType = null;

      rowWrappers[wrapperIndex].visible = true;

      if (wrapperIndex - 1 < adsWrappers.length) {
        adsWrappers[wrapperIndex - 1].visible = true;
      }
    }

    currentSportType = appendSportHeader(rows, match, currentSportType);
    rows.push(createMatchRow(match, rowIndex++, index === matches.length - 1));
  });

  return rowWrappers;
}

/**
 * Competition strategy
 * @param {Array} matches - matches list
 * @param {Array} rowWrappers - List of row wrappers
 * @param {Array} adsWrappers - List of ads wrappers
 * @param {Object} data - Custom data
 * @param {Date} data.selectedDate - Selected date
 * @returns {Array} - List of row wrappers with rows to render
 */
export function competition(matches, rowWrappers, adsWrappers, { selectedDate }) {
  let competitionKey = null;
  let rowIndex = 0;
  let competitionsCardsCount = 0;
  let wrapperIndex = 0;
  let rows = rowWrappers[wrapperIndex].rows;
  let matchDay = null;
  rowWrappers[wrapperIndex].visible = true;

  matches.forEach((match, index) => {
    if (competitionKey !== match.competitionKey) {
      if (rows.length) {
        rows[rows.length - 1].last = true;
      }

      if (
        wrapperIndex < rowWrappers.length - 1 &&
        (
          (wrapperIndex === 0 && rows.length >= 5) ||
          (wrapperIndex > 0 && ++competitionsCardsCount % 3 === 0)
        )
      ) {
        wrapperIndex++;
        rows = rowWrappers[wrapperIndex].rows;

        rowWrappers[wrapperIndex].visible = true;

        if (wrapperIndex - 1 < adsWrappers.length) {
          adsWrappers[wrapperIndex - 1].visible = true;
        }
      }

      rows.push({
        id: `widget-livescore-competition-header-${match.competitionKey}`,
        template: match.sportType !== Sport.TENNIS ? competitionHeader : tennisCompetitionHeader,
        data: match,
      });
      appendSportHeader(rows, match);
      competitionKey = match.competitionKey;
      matchDay = null;
      rowIndex = 0;
    }

    const newMatchDay = appendDateHeader(rows, match, matchDay, selectedDate);

    if (matchDay !== newMatchDay) {
      matchDay = newMatchDay;
      rowIndex = 0;
    }

    rows.push(
      createMatchRow(match, rowIndex, index === matches.length - 1)
    );

    rowIndex++;
  });

  return rowWrappers;
}
