import Base from './base';
import sortFactory from '../sort/factory';

import Sport from '../match/sport';

import NotUpdatedFilter from '../filter/not-updated';
import SnippetFilter from '../filter/snippet';

/**
 * Snippet variant config
 */
export default class Snippet extends Base {
  /**
   * @inheritdoc
   */
  get filters() { // eslint-disable-line class-methods-use-this
    return [
      new NotUpdatedFilter(),
      new SnippetFilter(),
    ];
  }

  /**
   * @inheritdoc
   */
  get defaultSports() {
    return [
      Sport.SOCCER,
      Sport.BASKETBALL,
    ];
  }

  /**
   * @inheritdoc
   */
  get sorter() { // eslint-disable-line class-methods-use-this
    return sortFactory('snippet');
  }
}
