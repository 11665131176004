export const ENV = {
  local: 'local',
  staging: 'staging',
  production: 'production',
};

let env = ENV.production;

/* eslint-disable no-console */
/**
 * Write to console
 * @param {string} level - log level
 * @param {...*} args - data to put into log
 */
export function log(level, ...args) {
  if (env === ENV.production) {
    return;
  }

  if (console && typeof console[level] === 'function') {
    console[level](...args);
  }
}
/* eslint-enable no-console */

/**
 * Throttles given function execution (does not run it no faster then ...)
 * @param {Function} fn - function to throttle
 * @param {number} [threshhold=250] - how long the throttle
 * @param {Object} [scope=this] - context
 * @returns {Function} throttled function
 */
export function throttle(fn, threshhold = 250, scope = null) {
  let last;
  let deferTimer;

  return function throttled(...args) {
    const context = scope || this;
    const now = +new Date();

    if (last && now < last + threshhold) { // hold on to it
      clearTimeout(deferTimer);

      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

/**
 * Debounces given function execution (delays)
 * @param {Function} fn - function to debounce
 * @param {number} [delay=250] - debounce delay duration
 * @returns {Function} - debounced function
 */
export function debounce(fn, delay = 250) {
  let timer = null;

  return function debounced(...args) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

/**
 * Do a shallow copy of object
 * @param {{}} source - source to copy
 * @returns {{}} - shallow copy of source object
 */
export function shallowCopy(source) {
  return Object.assign({}, source);
}

/**
 * Compare two date object if are in the same day
 * @param {Date} date1 - first date to compare
 * @param {Date} date2 - second date to compare
 * @returns {boolean} - true if are the same
 */
export function isSameDay(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

/**
 * Format JS Date to YYYY-MM-DD
 * @param {Date} date - date to format
 * @returns {string} formatted date
 */
export function formatDate(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const pad = value => {
    if (value < 10) {
      return `0${value}`;
    }

    return value;
  };

  return `${year}-${pad(month)}-${pad(day)}`;
}

/**
 * Performance API wrapper
 */
export class performanceAPI {
  /**
   * Safely call performance API
   * @param {string} funcName - function name from Performance API
   * @param {...*} args - function arguments
   * @returns {*} - result of performance function call or null
   */
  static call(funcName, ...args) {
    if (
      env !== ENV.production &&
      typeof performance !== 'undefined' &&
      typeof performance[funcName] === 'function'
    ) {
      return performance[funcName](...args);
    }

    return null;
  }

  /**
   * Clear performance marks
   * @param {...*} names - mark names to clear or none to clear all
   */
  static clearMarks(...names) {
    if (names.length === 0) {
      performanceAPI.call('clearMarks');
    } else {
      names.forEach(name => {
        performanceAPI.call('clearMarks', name);
      });
    }
  }

  /**
   * Log measure name to console
   * @param {string} name - measure name
   * @param {boolean} [clear=true] - set false to not clear measure after loggint it to console
   */
  static logMeasure(name, clear = true) {
    const measures = performanceAPI.call('getEntriesByType', 'measure');
    if (!measures || !measures.length) {
      return;
    }

    measures.filter(measure => measure.name === name)
      .forEach(measure => {
        log(
          'log',
          `Performance measure %c${measure.name}%c, task took: %c${measure.duration} ms`,
          'color: blue;', 'color: inherit', 'color: green'
        );
      });

    if (clear) {
      performanceAPI.call('clearMeasures', name);
    }
  }

  /**
   * Start measure
   * @param {string} measureName - measure name
   */
  static startMark(measureName) {
    performanceAPI.call('mark', `${measureName}_start`);
  }

  /**
   * End measure
   * @param {string} measureName - measure name
   */
  static endMark(measureName) {
    const startMark = `${measureName}_start`;
    const endMark = `${measureName}_end`;
    performanceAPI.call('mark', endMark);
    performanceAPI.call(
      'measure',
      measureName,
      startMark,
      endMark
    );
    performanceAPI.logMeasure(measureName);
    performanceAPI.clearMarks(startMark, endMark);
  }
}

/**
 * Set app environment
 * @param {string} newEnv - new env
 */
export function setEnv(newEnv) {
  env = newEnv;
}

/**
 * Get current app environment
 * @returns {string} - current env
 */
export function getEnv() {
  return env;
}
