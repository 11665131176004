const Short = {
  SOCCER: 'S',
  BASKETBALL: 'B',
  TENNIS: 'T',
};

export default Short;

export const Full = {
  [Short.SOCCER]: 'soccer',
  [Short.BASKETBALL]: 'basketball',
  [Short.TENNIS]: 'tennis',
};
