import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map.js';
import { baseClass } from './default';

export default (row) => {
  const { data: match } = row;
  const titleClasses = {
    [`${baseClass}__title`]: true,
    [`${baseClass}__title--sport-${match.sportType.toLowerCase()}`]: true,
  };

  const textClasses = {
    [`${baseClass}__text`]: true,
    [`${baseClass}__text--has-stage`]: match.stageName || match.seriesName,
  };

  const fullTitle = [
    match.countryName,
    match.stageName,
    match.competitionName,
    match.seriesName,
  ].filter(titlePart => titlePart);

  return html`
    <div class=${classMap(titleClasses)} id="competition-header-${match.competitionKey}"
        title="${fullTitle.join(' - ')}"
    >
      <span class=${classMap(textClasses)}>
      ${fullTitle.join(' - ')}
      </span>
    </div>`;
};
