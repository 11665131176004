import Base from './base';

/**
 * Soccer games filter
 */
export default class Sport extends Base {
  /**
   * @inheritdoc
   */
  constructor() {
    super('sport');
    this.sports = [];
  }

  /**
   * Set sports to filter
   * @param {string[]} sports - filtered sports
   */
  setSports(sports) {
    this.sports = sports.map(sport => sport.substring(0, 1).toUpperCase());
  }

  /**
   * @inheritdoc
   */
  apply(data) {
    return Object.keys(data)
      .map(key => [data[key], key])
      .filter(([entity]) => this.sports.indexOf(entity.sportType) !== -1)
      .reduce((dict, [entity, key]) => {
        dict[key] = entity;
        return dict;
      }, {});
  }
}
