/**
 * Base filter class
 */
export default class Base {
  /**
   * Filter constructor
   * @param {string} name - filter nmae
   */
  constructor(name) {
    this.name = name;
  }

  /**
   * Apply filter
   * @abstract
   * @param {Object} data - data to filter
   * @param {Array.<string>} competitions - list of sorted competition ids
   * @returns {Object} - filtered data
   * @throws {Error} NotImplementedError
   */
  apply(data, competitions) { // eslint-disable-line class-methods-use-this, no-unused-vars
    throw new Error('NotImplementedError');
  }

  /**
   * Get string representation of class
   * @returns {string} - string representation
   */
  toString() {
    return `${this.name} livescore filter`;
  }
}
