/**
 * Base sort class
 */
export default class Base {
  /**
   * Sorter constructor
   * @param {string} name - sorter name
   */
  constructor(name) {
    this.name = name;
  }
  /**
   * Apply sorting
   * @abstract
   * @param {Object} data - data to sort
   * @param {Array.<string>} competitions - list of sorted competition ids
   * @returns {Array.<Object>} - sorted data
   * @throws {Error} NotImplementedError
   */
  apply(data, competitions) { // eslint-disable-line class-methods-use-this, no-unused-vars
    throw new Error('NotImplementedError');
  }
}
