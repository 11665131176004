export default {
  // Generic
  FIXTURE: 'timestamp',
  LIVE: 'minutes',
  CANCELED: 'canceled',
  SUSPENDED: 'suspended',
  POSTPONED: 'postponed',
  STATE: 'state',
  FINISHED: 'fullTime',
  NONE: 'none',
  // Soccer
  HALF_TIME: 'halfTime',
  HALF_TIME_IN_EXTRA_TIME: 'halfTimeInExtraTime',
  AFTER_EXTRA_TIME: 'afterExtraTime',
  PENALTIES: 'penalties',
  // Basketball
  Q1: 'q1',
  Q2: 'q2',
  Q3: 'q3',
  Q4: 'q4',
  H1: 'h1',
  H2: 'h2',
  OVERTIME: 'ot',
  HALF_TIME_BREAK: 'halfTimeBreak',
  QUARTER_FIRST_BREAK: 'firstQuarterBreak',
  QUARTER_SECOND_BREAK: 'secondQuarterBreak',
  BEFORE_OVER_TIME: 'beforeOvertime',
  AFTER_OVER_TIME: 'afterOvertime',
};
