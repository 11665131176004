import { performanceAPI } from '../utils';

/**
 * Filter state class
 */
export default class State {
  /**
   * Filter state constructor
   */
  constructor() {
    this.filters = {};
  }

  /**
   * Add Filter
   * @param {Base} filter - filter to add
   * @returns {State} - return self for chaining
   */
  addFilter(filter) {
    this.filters[filter.name] = filter;
    return this;
  }

  /**
   * Remove filter
   * @param {Base} filter - filter to remove
   * @returns {State} - return self for chaining
   */
  removeFilter(filter) {
    delete this.filters[filter.name];
    return this;
  }

  /**
   * Get filter by filter nmae
   * @param {string} filterName - filter name
   * @returns {Base|null} - filter instance or null
   */
  getFilterByName(filterName) {
    return this.filters[filterName] || null;
  }

  /**
   * Check if filter is used
   * @param {Base} filter - filter to check
   * @returns {boolean} - true if filter is used
   */
  hasFilter(filter) {
    return Boolean(this.filters[filter.name]);
  }

  /**
   * Toggle filter
   * @param {Base} filter - filter to toggle
   * @param {boolean} [addOrRemove=null] - set true to force adding filter false to force
   * removing filter
   * @returns {State} - return self for chaining
   */
  toggleFilter(filter, addOrRemove = null) {
    if (addOrRemove === true) {
      this.addFilter(filter);
    } else if (addOrRemove === false) {
      this.removeFilter(filter);
    } else if (this.hasFilter(filter)) {
      this.removeFilter(filter);
    } else {
      this.addFilter(filter);
    }

    return this;
  }

  /**
   * Apply filters
   * @param {Object} data - data to filter
   * @param {Array.<string>} competitions - list of sorter competition ids
   * @returns {Object} - filtered data
   */
  apply(data, competitions) {
    performanceAPI.startMark('livescore_apply_filters');
    Object.keys(this.filters).forEach(filterName => {
      const filter = this.filters[filterName];
      data = filter.apply(data, competitions);
    });
    performanceAPI.endMark('livescore_apply_filters');

    return data;
  }
}
