import Base from './base';
import MatchStatus from '../match/status';

/**
 * Snippet filter
 */
export default class Snippet extends Base {
  /**
   * @inheritdoc
   */
  constructor() {
    super('snippet');
  }

  /**
   * @inheritdoc
   */
  apply(data) { // eslint-disable-line class-methods-use-this
    return Object.keys(data)
      .map(key => [data[key], key])
      .filter(([entity]) => {
        switch (entity.substate) {
          case MatchStatus.CANCELED:
          case MatchStatus.SUSPENDED:
          case MatchStatus.POSTPONED:
            return false;
          default:
            return true;
        }
      })
      .reduce((dict, [entity, key]) => {
        dict[key] = entity;
        return dict;
      }, {});
  }
}
