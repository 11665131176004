import Competition from './competition';
import KickoffTime from './kickoff-time';
import Snippet from './snippet';

const kickoffTime = new KickoffTime();
const competition = new Competition();
const snippet = new Snippet();

/**
 * Sorter factory
 * @param {string} type - sorter type (one of time or comp)
 * @returns {Base} - Sorter
 */
export default function factory(type) {
  switch (type) {
    case kickoffTime.name:
      return kickoffTime;
    case snippet.name:
      return snippet;
    case competition.name:
    default:
      return competition;
  }
}
