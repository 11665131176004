export default {
  FIRST_HALF: 1,
  SECOND_HALF: 2,
  FIRST_EXTRA_TIME: 3,
  SECOND_EXTRA_TIME: 4,
  PENALTIES: 5,
  FIRST_HALF_TIME: 10,
  SECOND_HALF_TIME: 11,
  HALF_TIME_IN_EXTRA_TIME: 12,
};
