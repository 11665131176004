import { GOAL, WHISTLE } from 'components/audio-player/audios';
import { play, clearPlayList } from 'components/audio-player';
import Side from '../match/side';

const eventSoundMap = {
  goals: GOAL,
  'red-cards': WHISTLE,
};

/**
 * Key Events Sound handler
 */
export default class KeyEventsSound {
  /**
   * Key Events Sound handler constructor
   * @param {string} [playListName='livescore'] - play list name
   */
  constructor(playListName = 'livescore') {
    this.muted = true;
    this.playListName = playListName;
  }

  /**
   * Mute and stop all key event sounds
   */
  mute() {
    clearPlayList(this.playListName);
    this.muted = true;
  }

  /**
   * Unmute key event sounds
   */
  unmute() {
    this.muted = false;
  }

  /**
   * Handle key events
   * @param {Object} keyEvents - list of key events
   */
  handleEvents(keyEvents) {
    if (this.muted) {
      return;
    }

    Object.keys(keyEvents)
      .filter(eventName => eventSoundMap[eventName])
      .forEach(eventName => {
        const eventData = keyEvents[eventName];
        let playCount = Object.keys(eventData).reduce(
          (count, key) => count + eventData[key][Side.HOME] + eventData[key][Side.AWAY],
          0
        );

        while (playCount--) {
          play(eventSoundMap[eventName], this.playListName);
        }
      });
  }
}
