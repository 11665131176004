import Base from './base';
import Live from './live';

/**
 * Snippet filter
 */
export default class LiveOrFixture extends Base {
  /**
   * @inheritdoc
   */
  constructor() {
    super('live-or-fixture');
    this.live = new Live();
    this.timeLimit = 15 * 60000; // 15 minutes
  }

  /**
   * @inheritdoc
   */
  apply(data, competitions) { // eslint-disable-line class-methods-use-this
    const now = Date.now();

    const liveMatches = this.live.apply(data, competitions);
    return Object.keys(data)
      .map(key => [data[key], key])
      .filter(([entity]) => entity.matchStartTime + this.timeLimit > now)
      .reduce((dict, [entity, key]) => {
        dict[key] = entity;
        return dict;
      }, liveMatches);
  }
}
