import Base from './base';

/**
 * Simple filter
 */
export default class Simple extends Base {
  /**
   * Simple filter constructor
   * @param {string} name - filter name
   * @param {string} field - field to check
   * @param {*} value - field value
   * @param {boolean} [equals=true] - true to check equality else check if field value is different
   */
  constructor(name, field, value, equals = true) {
    super(name);
    this.field = field;
    this.value = value;
    this.equals = equals;
  }

  /**
   * @inheritdoc
   */
  apply(data) {
    return Object.keys(data)
      .map(key => [data[key], key])
      .filter(([entity]) =>
        (this.equals ? entity[this.field] === this.value : entity[this.field] !== this.value)
      ).reduce((dict, [entity, key]) => {
        dict[key] = entity;
        return dict;
      }, {});
  }
}
