import MatchBase from '../match/base'; // eslint-disable-line no-unused-vars
import CompetitionTypes from '../match/competition-type';

/**
 * Link generator class
 */
export default class Linker {
  /**
   * Link generator constructor
   * @param {Object} templates - link templates
   */
  constructor(templates) {
    this.templates = templates;
  }

  /**
   * Generate link using template and template replacements
   * @param {string} template - link template
   * @param {Object} replacements - template replacements
   * @returns {string} - generated link
   */
  link(template, replacements) { // eslint-disable-line class-methods-use-this
    Object.keys(replacements).forEach(tag => {
      if (!replacements[tag]) {
        template = template.replace(new RegExp('/' + tag.toUpperCase(), 'g'), '');
        template = template.replace(new RegExp(tag.toUpperCase(), 'g'), '');
      } else {
        template = template.replace(new RegExp(tag.toUpperCase(), 'g'), replacements[tag]);
      }
    });

    return template;
  }

  /**
   * Get templated depending on match sport type
   * @param {MatchBase} match - match data
   * @returns {Object} - templates for given match sport type
   */
  forSport(match) {
    return this.templates[match.sportType];
  }

  /**
   * Generate link to competition page
   * @param {MatchBase} match - match data
   * @returns {string} - generated link
   */
  toCompetition(match) {
    const vars = {
      COUNTRY_SLUG: match.countrySlug,
      COMPETITION_SLUG: match.competitionSlug,
      COMPETITION_ID: match.competitionId,
    };

    const pageTemplate = match.competitionType === CompetitionTypes.LEAGUE ?
      'page' : 'pageCup';

    return this.link(this.forSport(match).competition[pageTemplate], vars);
  }

  /**
   * Generate link to competition flag image
   * @param {MatchBase} match - match data
   * @param {boolean} [srcset=false] - use srcset template
   * @param {string|null} [countryId=null] - force country id
   * @returns {string} - generated link
   */
  toFlag(match, srcset = false, countryId = null) {
    const vars = {
      COUNTRY_ID: countryId || match.countryId,
    };

    const template = srcset ?
      this.forSport(match).competition.flag.srcset : this.forSport(match).competition.flag.src;

    return this.link(template, vars);
  }

  /**
   * Generate link to match page
   * @param {MatchBase} match - match data
   * @returns {string} - generated link
   */
  toMatch(match) {
    const vars = {
      MATCH_SLUG: match.matchSlug,
      MATCH_ID: match.id,
    };

    let template = this.forSport(match).match.page;
    const minutesBeforeKickoff = Math.max(0, match.matchStartTime - Date.now()) / 60000;

    if (minutesBeforeKickoff >= 60) {
      template = this.forSport(match).match.alternativePage;
    }

    return this.link(template, vars);
  }

  /**
   * Generate link to team page
   * @param {MatchBase} match - match data
   * @param {string} side - team side, home or away
   * @returns {string} - generated link
   */
  toTeam(match, side) {
    const vars = {
      COMPETITION_SLUG: match.competitionSlug,
      SEASON_SLUG: match.seasonSlug,
      SEASON_ID: match.seasonId,
      TEAM_ID: match[`${side}TeamId`],
      TEAM_SLUG: match[`${side}TeamSlug`],
    };

    return this.link(this.forSport(match).team.page, vars);
  }

  /**
   * Generate link to team crest
   * @param {MatchBase} match - match data
   * @param {string} side - team side, home or away
   * @returns {string} - generated link
   */
  toCrest(match, side) {
    const vars = {
      TEAM_ID: match[`${side}TeamId`],
    };

    return this.link(this.forSport(match).team.crest, vars);
  }

  /**
   * Generate url for Iddaa layer
   * @param {MatchBase} match - match data
   * @returns {string} - generated link
   */
  toIddaaLayer(match) {
    const vars = {
      MATCH_ID: match.id,
      IDDAA_CODE: match.iddaaCode,
    };

    return this.link(this.forSport(match).iddaa, vars);
  }
}
