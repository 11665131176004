import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import Default from './default';
import { baseClass } from './base';
import Sport from '../../../match/sport';
import MatchStatus from '../../../match/status';
import Side from '../../../match/side';

/**
 * Soccer match row
 */
export default class Soccer extends Default {
  /**
   * Get aggregated score template
   * @returns {string|TemplateResult} - aggregated score template
   */
  get aggScore() {
    if (
      this.match.sportType === Sport.SOCCER &&
      this.match.scoreAggHome !== null &&
      this.match.scoreAggAway !== null
    ) {
      return html`
        <div class="${baseClass}__agg">
          ${this.translations.aggregate} ${this.match.scoreAggHome}-${this.match.scoreAggAway}
        </div>
    `;
    }

    return '';
  }

  /**
   * Get penalties score template
   * @returns {string|TemplateResult} - penalties score template
   */
  get penaltiesScore() {
    if (this.match.substate === MatchStatus.PENALTIES) {
      const penHome = this.match.scorePenHome || '0';
      const penAway = this.match.scorePenAway || '0';
      return html` <span class="${baseClass}__penalties-score">${penHome}-${penAway}</span>`;
    }

    return '';
  }

  /**
   * Get score label
   * @returns {string} - score label
   */
  get scoreLabel() {
    if (!this.match.scoreHome || !this.match.scoreAway) {
      return '';
    }

    return `${this.translations.goalLabel} ${this.match.scoreHome}-${this.match.scoreAway}`;
  }

  /**
   * Get live status text template
   * @returns {TemplateResult} - live status text template
   */
  get liveStatusText() {
    return html`${super.liveStatusText}${this.penaltiesScore}`;
  }

  /**
   * @inheritdoc
   */
  get afterContent() {
    return this.aggScore;
  }

  /**
   * @inheritdoc
   */
  get middleContent() {
    /* eslint-disable max-len */
    return html`
      <div class="${baseClass}__middle-container">
        <a href="${this.linker.toTeam(this.match, Side.HOME)}" data-jsblank="true"
          data-red-cards="${this.match.redCardsHome ? this.match.redCardsHome : 0}"
          class=${classMap(this.teamClasses(Side.HOME))}
        >
          <img width="18" height="18" class="${baseClass}__team-crest ${baseClass}__team-crest--home"
            data-src="${this.linker.toCrest(this.match, Side.HOME)}"
            alt="${this.match.homeTeamName}"
          >
          <span class="${baseClass}__team-name-text">
            ${this.match.homeTeamName}
          </span>
          ${this.redCards(Side.HOME)}
        </a>
        <a class="${baseClass}__score" data-jsblank="true" href="${this.matchUrl}">
          <span class="${baseClass}__score-home">${this.match.scoreHome}</span>-<span class="${baseClass}__score-away">${this.match.scoreAway}</span>
        </a>
        <a href="${this.linker.toTeam(this.match, Side.AWAY)}" data-jsblank="true"
          data-red-cards="${this.match.redCardsAway ? this.match.redCardsAway : 0}"
          class=${classMap(this.teamClasses(Side.AWAY))}
        >
          <img width="18" height="18" class="${baseClass}__team-crest ${baseClass}__team-crest--away"
            data-src="${this.linker.toCrest(this.match, Side.AWAY)}"
            alt="${this.match.awayTeamName}"
          >
          <span class="${baseClass}__team-name-text">
            ${this.match.awayTeamName}
          </span>
          ${this.redCards(Side.AWAY)}
        </a>
      </div>
    `;
    /* eslint-enable max-len */
  }

  /**
   * Get red cards template
   * @param {string} side - team side
   * @returns {string|TemplateResult} - red cards template result or empty string
   */
  redCards(side) {
    const key = `redCards${side.charAt(0).toUpperCase() + side.slice(1)}`;
    const count = (this.match[key] || 0);

    if (count) {
      const width = (count * 10) + ((count - 1) * 2);
      return html`
        <span class="${baseClass}__red-cards" style="width: ${width}px"></span>
      `;
    }

    return '';
  }
}
