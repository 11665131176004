import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map.js';
import flag from '../flag';

export const baseClass = 'widget-livescore';

export default (row, linker = null) => {
  const { data: match } = row;
  const stageParts = ['stageName', 'seriesName']
    .filter(name => match[name])
    .map(name => match[name])
    .join(' - ')
  ;

  const titleClasses = {
    [`${baseClass}__title`]: true,
    [`${baseClass}__title--sport-${match.sportType.toLowerCase()}`]: true,
  };

  const textClasses = {
    [`${baseClass}__text`]: true,
    [`${baseClass}__text--has-stage`]: stageParts,
  };

  return html`
    <div class=${classMap(titleClasses)} id="competition-header-${match.competitionKey}">
      <a href="${linker.toCompetition(match)}" data-jsblank="true"
        class="${baseClass}__competition-link"
      >
        ${flag(match, linker, [`${baseClass}__title-flag`])}
        <span class=${classMap(textClasses)}>
          <span class="${baseClass}__competition-name
            ${baseClass}__competition-name--full">
            ${match.competitionName}
          </span>
          <span class="${baseClass}__competition-name
            ${baseClass}__competition-name--code">
            <abbr title="${match.competitionName}">${match.competitionName}</abbr>
          </span>
          ${stageParts ? ` - ${stageParts}` : ''}
        </span>
      </a>
    </div>`;
};
