import Base from './base';
import Simple from './simple';
import LiveOrFixtureFilter from './live-or-fixture';

/**
 * Soccer games filter
 */
export default class LiveBetting extends Base {
  /**
   * @inheritdoc
   */
  constructor() {
    super('live-betting');
    this.liveBetting = new Simple('live-betting-is-active', 'liveBetting', true);
    this.liveOrFixture = new LiveOrFixtureFilter();
  }

  /**
   * @inheritdoc
   */
  apply(data) {
    return this.liveBetting.apply(this.liveOrFixture.apply(data));
  }
}
