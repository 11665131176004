import { html } from 'lit-html';
import Sport from '../../match/sport';

/**
 * Get tennis header template
 * @param {Object} row - row data
 * @param {Linker} linker - linker object
 * @param {Object} data - custom data
 * @param {Object} data.translations - translations map
 * @returns {TemplateResult} - tennis header template
 */
function tennisHeader(row, linker, { translations }) {
  const sets = [];

  for (let setNum = 1; setNum < 6; setNum++) {
    sets.push(html`<span class="widget-livescore__sets-header-cell">${setNum}</span>`);
  }

  return html`
    <div class="widget-livescore__sport-header widget-livescore__sport-header--sport-t">
      <div class="widget-livescore__sets-header">
        <span class="widget-livescore__sets-header-cell widget-livescore__sets-header-cell--full">
          ${translations.tennisScoreHeader}
        </span>
        ${sets}
        <span class="widget-livescore__sets-header-cell widget-livescore__sets-header-cell--last">
          ${translations.liveSetScoreHeader}
        </span>
      </div>
    </div>
  `;
}

/**
 * Get sport specific header
 * @param {string} sportType - sport type
 * @returns {null|TemplateResult} - sport header template result or null
 */
export default function sportHeader(sportType) {
  switch (sportType) {
    case Sport.TENNIS:
      return tennisHeader;
    default:
      return null;
  }
}
