import { getSetting } from 'components/vendor/perform/localization';
import 'widgets/social/post/style.scss';

/**
 * Social post widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - widget settings
 */
export default function (context, settings) {
  const type = settings.type;
  const fbSdk = 'facebook-jssdk';
  const $window = $(window);
  const $context = $(context);
  const isMobile = !$('html').hasClass('dev-desktop');
  const $content = $context.find('.widget-social-post__content');
  let template = '<div class="fb-post" data-width="auto" data-href="%%url%%"></div>';

  /**
   * Add facebook sdk script
   */
  function addFacebookSdk() {
    if (document.getElementById(fbSdk)) {
      return;
    }

    const js = document.createElement('script');
    const fjs = document.getElementsByTagName('script')[0];

    js.id = fbSdk;
    js.src = `https://connect.facebook.net/${getSetting('localization.locale')}/sdk.js#xfbml=1&version=v3.0`;
    fjs.parentNode.insertBefore(js, fjs);
  }

  if (type === 'facebook') {
    addFacebookSdk();
    template = template.replace('%%url%%', settings.externalUrl);

    if (isMobile) {
      $window.on('resize-x', () => {
        $content.empty();
        $content.append(template);
        /*eslint-disable */
        if (FB !== undefined) {
          setTimeout(() => {
            FB.XFBML.parse();
          }, 100);
        }
        /*eslint-enable */
      });
    }
  }
}
