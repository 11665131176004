import competitionTables from 'p0compLocal/competition/tables/base';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import globalPubsub from 'pubsub.js';

/**
 * Competition tables widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 * @param {string} settings.url - Ajax request url
 * @param {Object} settings.asyncRequestParams - Parameters passed to asynchronous request
 * @param {boolean} settings.initialized - True if component was initialized in synchronous version
 */
export default function (context, settings) {
  const $context = $(context);
  const classWidget = 'p0c-competition-tables';
  const selectorWidget = `.${classWidget}`;
  const eventNamespace = pubsubNamespace(context);
  const pubsub = $context.data('pubsub') || globalPubsub;
  const classTabs = 'component-tabs';
  const selectorTabs = `.${classTabs}`;
  const selectorGroup = `${selectorWidget}__group`;
  const classFilterBar = `${classWidget}__filter-bar`;
  const selectorFilterBar = `.${classFilterBar}`;
  const $filterBar = $context.find(selectorFilterBar);
  const classMatchStatus = `${classWidget}__match-status`;
  const selectorMatchStatus = `.${classMatchStatus}`;
  const selectorDataSetMissing = `${selectorMatchStatus}--missing`;
  const classDataSet = `${classWidget}__dataset`;
  const selectorDataSet = `.${classDataSet}`;
  const selectorDataSetForms = `${selectorDataSet}--forms`;
  const classVisualIndicatorLast = `${classMatchStatus}--visual-indicator-last`;
  const classDataSetToggleable = `${classDataSet}--toggleable`;
  const selectorDataSetToggleable = `.${classDataSetToggleable}`;
  const classDataSetHidden = `${classDataSet}--toggled-hidden`;
  competitionTables(context, settings);
  const selectorDropdown = '.component-dropdown';

  pubsub.subscribe(`${eventNamespace}/ajax/complete`, (eventContext) => {
    if (context !== eventContext) {
      return;
    }

    $context.find(selectorGroup).each(function initGroupTabs() {
      initTabs($(this));
    });
    determineFilterSelectorWidth();
    pointLastVisualIndicators();
  });

  pubsub.subscribe(`${eventNamespace}/competition-change`, (eventContext, competition) => {
    if (context !== eventContext ||
      settings.asyncRequestParams.competitionId === competition.id
    ) {
      return;
    }

    settings.asyncRequestParams.competitionId = competition.id;
  });

  /**
   * points lasts visual indicators for each forms set when filter changed to form
   */
  function pointLastVisualIndicators() {
    $context.find(selectorDataSetForms).each(function pointLastPlayedForm() {
      $(this)
        .find(`${selectorMatchStatus}:not(${selectorDataSetMissing})`)
        .last()
        .addClass(classVisualIndicatorLast);
    });
  }

  /**
   * calculate width of newly loaded filter dropdown
   */
  function determineFilterSelectorWidth() {
    const filterDropdownContext = $filterBar.find(selectorDropdown).get(0);
    const dropdownNamespace = pubsubNamespace(filterDropdownContext);

    pubsub.subscribe(`${dropdownNamespace}/rendered`, dropdownEventContext => {
      if (dropdownEventContext !== filterDropdownContext) {
        return;
      }

      pubsub.publish(`${dropdownNamespace}/resize`, [filterDropdownContext]);
    });
  }

  /**
   * initialize competition tables tabs when filter changed
   * @param {Object} $ctx - jQuery object of group context
   */
  function initTabs($ctx) {
    const $tabs = $ctx.find(selectorTabs);

    if (!$tabs.length) {
      return;
    }

    const tabsContext = $tabs.get(0);
    const tabsNamespace = pubsubNamespace(tabsContext);
    const $toggleableData = $ctx.find(selectorDataSetToggleable);

    pubsub.subscribe(`${tabsNamespace}/change`, (tabsEventContext, params) => {
      if (tabsContext !== tabsEventContext || !params.tabId) {
        return;
      }

      $toggleableData.addClass(classDataSetHidden);

      $toggleableData
        .filter(`${selectorDataSet}--${params.tabId}`)
        .removeClass(classDataSetHidden);
    });

    pubsub.subscribe(`${tabsNamespace}/rendered`, tabsEventContext => {
      if (tabsContext !== tabsEventContext) {
        return;
      }

      ['totalCol', 'home', 'away'].forEach(tabId => {
        pubsub.publish(`${tabsNamespace}/plug-tab-content`, [tabsContext, {
          tabId,
          tabContentSelector: null,
        }]);
      });
    });
  }

  determineFilterSelectorWidth();
}
