import Base from './base';
import { performanceAPI } from '../utils';
import SoccerPeriodId from '../match/period-id/soccer';
import BasketballPeriodId from '../match/period-id/basketball';
import Sport from '../match/sport';
import State from '../match/state';
import Status from '../match/status';

/**
 * Kickoff time order sorter
 */
export default class KickoffTime extends Base {
  /**
   * Kickoff time sorter constructor
   */
  constructor() {
    super('time');
  }

  /**
   * Get specific weight of certain match period
   * @param {string} periodId - match periodId
   * @param {string} sport - match sport
   * @returns {number} weight of match period
   */
  static getPeriodWeight(periodId, sport) {
    if (sport === Sport.SOCCER) {
      switch (periodId) {
        case SoccerPeriodId.FIRST_HALF:
          return 1;
        case SoccerPeriodId.FIRST_HALF_TIME:
          return 2;
        case SoccerPeriodId.SECOND_HALF:
          return 3;
        case SoccerPeriodId.SECOND_HALF_TIME:
          return 4;
        case SoccerPeriodId.FIRST_EXTRA_TIME:
          return 5;
        case SoccerPeriodId.HALF_TIME_IN_EXTRA_TIME:
          return 6;
        case SoccerPeriodId.SECOND_EXTRA_TIME:
          return 7;
        case SoccerPeriodId.PENALTIES:
          return 8;
        default:
          return 0;
      }
    } else if (sport === Sport.BASKETBALL) {
      switch (periodId) {
        case BasketballPeriodId.FIRST_QUARTER:
        case BasketballPeriodId.FIRST_HALF:
          return 1;
        case BasketballPeriodId.QUARTER_FIRST_BREAK:
          return 2;
        case BasketballPeriodId.SECOND_QUARTER:
          return 3;
        case BasketballPeriodId.QUARTER_SECOND_BREAK:
        case BasketballPeriodId.HALF_TIME_BREAK:
          return 4;
        case BasketballPeriodId.THIRD_QUARTER:
        case BasketballPeriodId.SECOND_HALF:
          return 5;
        case BasketballPeriodId.FOURTH_QUARTER:
          return 6;
        case BasketballPeriodId.OVERTIME_TO_BE_PLAYED:
          return 7;
        case BasketballPeriodId.OVERTIME:
          return 8;
        default:
          return 0;
      }
    }

    return 0;
  }

  /**
   * Get state weight
   * @param {string} state - match state
   */
  static getStateWeight(state) {
    switch (state) {
      case State.PRE:
        return 1;
      case State.LIVE:
        return 2;
      case State.POST:
        return 3;
      default:
        return 0;
    }
  }

  /**
   * Check if substate is valid to use state weight for ordering
   * @param {string} substate - substate to check
   * @returns {boolean} - true if this substate is ok
   */
  static isValidSubstateForStateWeight(substate) {
    switch (substate) {
      case Status.CANCELED:
      case Status.SUSPENDED:
      case Status.POSTPONED:
        return false;
      default:
        return true;
    }
  }

  /**
   * @inheritdoc
   */
  apply(data, competitions) { // eslint-disable-line class-methods-use-this
    const performanceMarkName = 'livescore_kickoff_time_sort';

    performanceAPI.startMark(performanceMarkName);

    const sorted = Object.keys(data)
      .map(key => data[key])
      .sort((matchA, matchB) => {
        if (matchA.sportType !== Sport.TENNIS || matchB.sportType !== Sport.TENNIS) {
          if (
            KickoffTime.isValidSubstateForStateWeight(matchA.substate) &&
            KickoffTime.isValidSubstateForStateWeight(matchB.substate)
          ) {
            const matchAStateWeight = KickoffTime.getStateWeight(matchA.state);
            const matchBStateWeight = KickoffTime.getStateWeight(matchB.state);

            if (matchAStateWeight && matchBStateWeight && matchAStateWeight !== matchBStateWeight) {
              return matchBStateWeight - matchAStateWeight;
            }
          }

          if (
            matchA.state === State.LIVE && matchB.state === State.LIVE &&
            matchA.sportType === matchB.sportType
          ) {
            const matchAPeriodWeight = KickoffTime
              .getPeriodWeight(matchA.periodId, matchA.sportType);
            const matchBPeriodWeight = KickoffTime
              .getPeriodWeight(matchB.periodId, matchB.sportType);

            if (matchAPeriodWeight === matchBPeriodWeight && matchA.sportType === Sport.SOCCER) {
              return matchA.periodStart - matchB.periodStart;
            }

            return matchBPeriodWeight - matchAPeriodWeight;
          }
        }

        if (matchA.matchStartTime === matchB.matchStartTime) {
          return competitions.indexOf(matchA.competitionKey) -
            competitions.indexOf(matchB.competitionKey);
        }

        return matchA.matchStartTime - matchB.matchStartTime;
      });
    performanceAPI.endMark(performanceMarkName);

    return sorted;
  }
}
