import { pubsubNamespace } from 'components/vendor/perform/utils';
import globalPubsub from 'pubsub.js';

/**
 * Filters handler class
 */
export default class Filters {
  /**
   * Fiters hanlder constructor
   * @param {HTMLElement} context - handler context
   * @param {Function} callback - change callback
   * @param {PubSubJS} [pubsub=null] - pubsub instance
   * @param {string} [namespace=null] - pubsub event namespace
   */
  constructor(context, callback, pubsub = null, namespace = null) {
    this.context = context;
    this.namespace = namespace || pubsubNamespace(context);
    this.pubsub = pubsub || globalPubsub;
    this.values = {};
    this.callback = callback;
  }

  /**
   * Add new filter change handler
   * @param {string} handlerName - handler name
   * @param {Function} handler - handler callback
   * @param {string} value - starting value of filter
   * @param {boolean} [runCallback=true] - set false to not run update callback
   * @returns {Filters} - self for chaining
   */
  addHandler(handlerName, handler, value, runCallback = true) {
    this.values[handlerName] = value;
    this.pubsub
      .subscribe(`${this.namespace}/${handlerName}`, (eventContext, newValue, ...args) => {
        const valueToSave = args.length ? JSON.stringify([newValue, ...args]) : newValue;

        if (eventContext !== this.context || this.values[handlerName] === valueToSave) {
          return;
        }

        this.values[handlerName] = valueToSave;
        handler(newValue, ...args);
        if (runCallback) {
          this.callback();
        }
      });
    return this;
  }
}
