import { forEachElement } from 'components/utils';

const STARTTIME_CHECK_TIMEOUT = 60000; // 1 minute
const attributesToRemove = [
  'href', 'data-odd-link', 'target', 'rel', 'data-mobile-href', 'data-jsblank',
  'data-mobile-blank', 'data-ga-category', 'data-ga-action', 'data-ga-label',
  'data-kickoff-time',
];

/**
 * Check event start time and remove odd link when event has started
 * @param {HTMLElement} context - context to check
 */
function checkEventStartTime(context) {
  const links = context.querySelectorAll('[data-odd-link]:not([data-force-link])');
  const now = Date.now();

  forEachElement(links, (link) => {
    const { kickoffTime } = link.dataset;

    if (!kickoffTime) {
      return;
    }

    if (Number(kickoffTime) < now) {
      attributesToRemove.forEach((attributeName) => {
        link.removeAttribute(attributeName);
      });
    }
  });
}

/**
 * Common iddaa widget script
 * @param {HTMLElement} context - context
 */
export default function (context) {
  /**
   * Schedule event start time check
   */
  function scheduleEventStarttimeCheck() {
    setTimeout(() => {
      checkEventStartTime(context);
      scheduleEventStarttimeCheck();
    }, STARTTIME_CHECK_TIMEOUT);
  }

  scheduleEventStarttimeCheck();
}
