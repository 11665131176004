import Simple from './simple';

/**
 * Iddaa filter
 */
export default class Iddaa extends Simple {
  /**
   * @inheritdoc
   */
  constructor() {
    super('iddaa', 'iddaaCode', null, false);
  }
}
