import { applyEventsMap } from 'components/ga';

const selectorLiveScore = '.widget-livescore';
const selectorLiveScoreMatchLink = `${selectorLiveScore}__status,${selectorLiveScore}__score`;
const selectorMainNavLink = '.widget-navigation-primary__item a';
const selectorLogo = '.widget-header__logo';

const gaEventMapping = {
  [selectorLiveScoreMatchLink]: {
    category: 'CanliSonuclar',
    action: 'OnClick',
    label: 'Mac',
  },
  [selectorMainNavLink]: {
    category: 'MainMenu',
    action: 'MenuClick',
  },
  [selectorLogo]: {
    category: 'MainMenu',
    action: 'MenuClick',
    label: 'Logo',
  },
};

applyEventsMap(gaEventMapping, (selector, $item, eventData) => {
  if (selector === selectorMainNavLink) {
    eventData.label = $item.data('gaLabel');
  }

  return true;
});
