import lozad from 'lozad';
import { onRAF } from 'components/vendor/perform/utils';

require('intersection-observer');
require('components/lazy-loader/style.scss');

let observer;

/**
 * Initialize lozad observer for given context
 * @returns {LozadObserver} - lozad observer
 */
export default function () {
  if (!observer) {
    observer = lozad('img[data-src], img[data-src-set]', {
      threshold: 0.1,
      loaded(img) {
        onRAF(() => img.setAttribute('data-state', 'loaded'));
      },
    });
  }

  observer.observe();

  return observer;
}
