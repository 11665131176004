import { getWrappers, resetAdSlot } from './wrappers';

const className = 'widget-livescore__no-data';

/**
 * Render empty message
 * @param {HTMLElement} context - element where rendered content should go
 * @param {Object} data - additional data
 * @param {string} data.message - message to display
 * @param {Array} data.adSlots - ad slots to reset
 */
export default function render(context, { message, adSlots }) {
  getWrappers(adSlots).ads.forEach(({ wrapper }) => {
    resetAdSlot(wrapper.firstElementChild);
  });

  context.innerHTML = `
    <div class="${className}">
      ${message}
    </div>
  `;
}

/**
 * Remove empty message from passed context
 * @param {HTMLElement} context - context to check
 */
export function removeEmptyMessage(context) {
  const message = context.querySelector(`.${className}`);
  if (message && message.parentNode === context) {
    context.removeChild(message);
  }
}
