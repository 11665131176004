import sortFactory from '../sort/factory';
import SortBase from '../sort/base'; // eslint-disable-line no-unused-vars

/**
 * Base Livescore variant config
 */
export default class Base {
  /**
   * True if key events shouds should be enabled
   * @returns {boolean} - true if event sounds should be enabled
   */
  get keyEventsSounds() { // eslint-disable-line class-methods-use-this
    return false;
  }

  /**
   * Get default sports for this variant
   * @returns {string[]} - array of sports
   */
  get defaultSports() {
    return [];
  }

  /**
   * Get default filters
   * @returns {Array.<SortBase>} - array of default filters
   */
  get filters() { // eslint-disable-line class-methods-use-this
    return [];
  }

  /**
   * Get default sorter
   * @returns {SortBase} - sort strategy
   */
  get sorter() { // eslint-disable-line class-methods-use-this
    return sortFactory('comp');
  }
}
