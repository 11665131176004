import Base from './base';
import factory from './factory';
import { performanceAPI } from '../utils';
import MatchStatus from '../match/status';
import MatchState from '../match/state';

/**
 * Competition order sorter
 */
export default class Snippet extends Base {
  /**
   * Competition sorter constructor
   */
  constructor() {
    super('snippet');
    this.kickoffTime = factory('time');
  }

  /**
   * Get Substate weight to use it in ordering
   * @param {string} substate - substate to check
   * @returns {number} - substate weight
   */
  static getSubstateWeight(substate) {
    switch (substate) {
      case MatchStatus.FINISHED:
      case MatchStatus.PENALTIES:
      case MatchStatus.AFTER_EXTRA_TIME:
      case MatchStatus.AFTER_OVER_TIME:
        return 1;
      case MatchStatus.SUSPENDED:
        return 2;
      case MatchStatus.POSTPONED:
        return 3;
      case MatchStatus.CANCELED:
        return 4;
      default:
        return 0;
    }
  }

  /**
   * @inheritdoc
   */
  apply(data, competitions) { // eslint-disable-line class-methods-use-this
    const performanceMarkName = 'livescore_snippet_sort';

    performanceAPI.startMark(performanceMarkName);

    const sorted = this.kickoffTime
      .apply(data, competitions)
      .sort((matchA, matchB) => {
        if (matchA.state === MatchState.LIVE || matchB.state === MatchState.LIVE) {
          if (matchA.state === matchB.state) {
            return 0;
          } else if (matchA.state === MatchState.LIVE) {
            return -1;
          }

          return 1;
        }

        const statusA = this.constructor.getSubstateWeight(matchA.substate);
        const statusB = this.constructor.getSubstateWeight(matchB.substate);

        return statusA - statusB;
      }).slice(0, 14);

    performanceAPI.endMark(performanceMarkName);

    return sorted;
  }
}
