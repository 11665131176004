/**
 * Base click handler
 */
export default class ClickHandler {
  /**
   * Find closest element matching selector
   * @param {HTMLElement} element - starting element
   * @param {string} selector - selector to match
   * @returns {HTMLElement|null} - matched element or null if not found
   */
  closest(element, selector) {
    do {
      if (element.matches(selector)) {
        return element;
      }

      element = element.parentNode;
    } while (element && element !== this.context);

    return null;
  }
}
