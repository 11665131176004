import { html, render } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat.js';
import { render as coreRender } from 'components/vendor/perform/core';
import Linker from './linker';
import { performanceAPI } from '../utils';
import * as Strategies from './strategy';
import { getWrappers, resetAdSlot, TYPE_ROWS, TYPE_AD_SLOT } from './wrappers';
import { removeEmptyMessage } from './empty';

/**
 * Render list of matches
 * @param {HTMLElement} context - element where rendered content should go
 * @param {Array.<Object>} matches - list of matches
 * @param {Object} data - additional data like translations
 */
export default (context, matches, data) => {
  performanceAPI.startMark('livescore_render_list');

  const linker = new Linker(data.urlTemplates);
  const wrappers = getWrappers(data.adSlots);
  const strategy = data.showCompetitionHeaders ? Strategies.competition : Strategies.simple;

  strategy(matches, wrappers.rows, wrappers.ads, data);

  removeEmptyMessage(context);

  wrappers.all.forEach(({ wrapper, rows, type, visible }) => {
    if (type === TYPE_ROWS) {
      render(
        html`${repeat(rows, row => row.id, row => html`${row.template(row, linker, data)}`)}`,
        wrapper
      );
    }

    if (visible && !wrapper.parentNode) {
      context.appendChild(wrapper);

      if (type === TYPE_AD_SLOT) {
        resetAdSlot(wrapper.firstElementChild);
      }
    } else if (!visible && wrapper.parentNode) {
      context.removeChild(wrapper);
    }
  });

  coreRender();
  performanceAPI.endMark('livescore_render_list');
};
