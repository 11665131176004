import Base from './base';
import Sport from '../match/sport';

import NotUpdated from '../filter/not-updated';

/**
 * Full variant config
 */
export default class Full extends Base {
  /**
   * @inheritdoc
   */
  get keyEventsSounds() { // eslint-disable-line class-methods-use-this
    return true;
  }

  /**
   * @inheritdoc
   */
  get defaultSports() {
    return [
      Sport.SOCCER,
      Sport.BASKETBALL,
    ];
  }

  /**
   * @inheritdoc
   */
  get filters() { // eslint-disable-line class-methods-use-this
    return [
      new NotUpdated(),
    ];
  }
}
