import Promise from 'promise-polyfill';
import { Howl, Howler } from 'howler';
import { log } from 'components/vendor/perform/core';
import { SILENCE } from './audios';

const extensions = ['webm', 'mp3', 'ogg', 'wav'];
const filenames = {};
const playLists = {};
const activeAudios = {};
let playabilityPromise;

Howler.autoUnlock = false;

/**
 * Get or create playlist
 * @param {string} playListName - playlist name
 * @returns {Array} - play list
 */
function getPlayList(playListName) {
  if (!playLists[playListName]) {
    playLists[playListName] = [];
  }

  return playLists[playListName];
}

/**
 * Add sound to playlist
 * @param {string} filename - sound filename
 * @param {string} [playListName=default] - name of playlist
 * @returns {Howl} - audio wrapper for filename
 */
function play(filename, playListName = 'default') {
  const audio = getAudio(filename);
  const playList = getPlayList(playListName);
  playList.push(audio);
  playSoundFromPlaylist(playListName);

  return audio;
}

/**
 * Mute/Unmute global sound
 * @param {boolean} muted - true to mute and false to unmute
 */
function globalMute(muted = true) {
  Howler.mute(muted);
}

/**
 * Get audio handler
 * @param {string} filename - audio filename
 * @returns {Howl} - audio wrapper for filename
 */
function getAudio(filename) {
  if (filenames[filename]) {
    return filenames[filename];
  }

  const audio = new Howl({
    src: extensions.map(ext => `${window.location.origin}/audio/${filename}.${ext}`),
  });

  filenames[filename] = audio;

  return audio;
}

/**
 * Play sound from playlist
 * @param {string} [playListName='default'] - play list name
 * @returns {Howl|null} - active audio track
 */
function playSoundFromPlaylist(playListName = 'default') {
  const playList = getPlayList(playListName);
  let activeAudio = activeAudios[playListName];

  if (!playList.length || activeAudio) {
    return activeAudio || null;
  }

  activeAudios[playListName] = playList.shift();
  activeAudio = activeAudios[playListName];

  activeAudio.once('end', () => {
    delete activeAudios[playListName];
    playSoundFromPlaylist(playListName);
  });

  activeAudio.play();

  return activeAudio;
}

/**
 * Clear play list
 * @param {string} [playListName='default'] - play list name
 */
function clearPlayList(playListName = 'default') {
  const playList = getPlayList(playListName);
  const activeAudio = activeAudios[playListName];
  playList.splice(0, playList.length);

  if (activeAudio) {
    activeAudio.stop();
    delete activeAudios[playListName];
  }
}

/**
 * Check if audio can be played
 * @returns {Promise} - playability promise
 */
function checkPlayability() {
  if (playabilityPromise) {
    return playabilityPromise;
  }

  playabilityPromise = new Promise((resolve) => {
    const audio = getAudio(SILENCE);

    /**
     * On play listener
     */
    function onPlay() {
      removeListeners();
      audio.stop();
      resolve(true);
    }

    /**
     * On play error listener
     */
    function onPlayError() {
      removeListeners();
      log('Need user action to play sound');
      resolve(false);
    }

    /**
     * On load listener
     */
    function onLoad() {
      removeListeners();
      audio.play();
      if (!audio.playing()) {
        onPlayError();
      } else {
        onPlay();
      }
    }

    /**
     * Remove all listeners
     */
    function removeListeners() {
      audio.off('play', onPlay);
      audio.off('load', onLoad);
      audio.off('playerror', onPlayError);
    }

    audio.once('play', onPlay);
    audio.once('playerror', onPlayError);
    audio.once('load', onLoad);

    audio.play();
  });

  return playabilityPromise;
}

export {
  play,
  globalMute,
  clearPlayList,
  getAudio,
  checkPlayability,
};
