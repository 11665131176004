import { isMobileDevice } from 'assets/js/main.js-next';
import { pushEvent } from 'components/ga';
import ClickHandler from './click-handler';
import Sport from '../match/sport';

/**
 * Match row click handler for mobile devices
 */
class MatchRowClick extends ClickHandler {
  /**
   * Match row click handler constructor
   * @param {HTMLElement} context - handler context
   * @param {string} [dataName='matchUrl'] - click element data name
   * @param {string} [selector='[data-match-url]:not([data-match-url=""]']
   * - selector which element should match
   * @param {Array} [excludeSelectors=['.match-row__favourite', '.match-row__iddaa',
   * '.match-row__iddaa-layer', '.match-row__iddaa-markets']]
   * - which selectors should be excluded
   */
  constructor(
    context,
    dataName = 'matchUrl',
    selector = '[data-match-url]:not([data-match-url=""])',
    excludeSelectors = [
      '.match-row__favourite',
      '.match-row__iddaa',
      '.match-row__iddaa-layer',
      '.match-row__iddaa-markets',
    ]
  ) {
    super();
    this.context = context;
    this.dataName = dataName;
    this.sportDataName = 'sport';
    this.selector = selector;
    this.excludeSelector = excludeSelectors.join(',');

    this.onClick = this.onClick.bind(this);

    this.context.addEventListener('click', this.onClick, false);
  }

  /**
   * Detach handler from context
   */
  detach() {
    this.context.removeEventListener('click', this.onClick, false);
  }

  /**
   * Check if click is allowed
   * @param {HTMLElement} matchRow - match row element
   * @returns {boolean} - true if click handler should be fired
   */
  isClickAllowed(matchRow) {
    if (!matchRow) {
      return false;
    }

    return isMobileDevice() || matchRow.dataset[this.sportDataName] === Sport.TENNIS;
  }

  /**
   * Click handler
   * @param {Event} event - event object
   */
  onClick(event) {
    const matchRow = this.closest(event.target, this.selector);

    if (!this.isClickAllowed(matchRow) || this.closest(event.target, this.excludeSelector)) {
      return;
    }

    const rowUrl = matchRow.dataset[this.dataName];

    if (this.closest(event.target, 'a')) {
      event.preventDefault();
    }

    pushEvent({
      category: 'LiveScoreMatches',
      action: 'MatchClick',
      label: 'MacDetay',
    });

    event.stopPropagation();
    event.stopImmediatePropagation();

    if (isMobileDevice()) {
      window.location.href = rowUrl;
    } else {
      const link = document.createElement('a');
      link.href = rowUrl;
      link.target = '_blank';
      link.rel = 'noopener';

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }
}

export default (...args) => new MatchRowClick(...args);
