import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { repeat } from 'lit-html/directives/repeat';
import MatchRow, { baseClass } from './base';
import Side from '../../../match/side';
import State from '../../../match/state';
import flag, { UNKNOWN_ID } from '../flag';

const ucFirst = (string) => `${string.charAt(0).toUpperCase() + string.slice(1)}`;

/**
 * Tennis match row
 */
export default class Tennis extends MatchRow {
  /**
   * Get team flags template
   * @param {string} side - team side
   * @returns {string|TemplateResult} - flags template or empty string
   */
  teamFlags(side) {
    let nationalityIds = this.match[`${side}TeamNationalityIds`];
    let nationalityCodes = this.match[`${side}TeamNationalityCodes`];

    if (!nationalityIds || !nationalityIds.length) {
      nationalityIds = [UNKNOWN_ID];
      nationalityCodes = [''];
    }

    const classes = {
      [`${baseClass}__team-flags`]: true,
      [`${baseClass}__team-flags--mutliple`]: nationalityIds.length > 1,
    };

    return html`
      <span class=${classMap(classes)}>
        ${repeat(
          nationalityIds.slice(0, 2),
          (id) => `${this.match[`${side}TeamId`]}-${id}`,
          (id, index) => flag(this.match, this.linker, [
            `${baseClass}__team-flag`,
            `${baseClass}__team-flag--${index + 1}`,
          ], id, nationalityCodes[index])
        )}
      </span>
    `;
  }

  /**
   * Get set scores template
   * @param {string} side - team side
   * @returns {TemplateResult} - scores template
   */
  setScores(side) {
    const scores = [];

    for (let setNum = 1; setNum < this.match.numberOfSets + 1; setNum++) {
      const setScore = this.setScore(side, setNum);

      scores.push({
        template: setScore,
        id: `${this.match.id}-${side}-${setNum}`,
      });
    }

    return html`
      ${repeat(scores, (score) => score.id, (score) => score.template)}
    `;
  }

  /**
   * Get set score template
   * @param {string} side - team side
   * @param {number} setNum - set number
   * @returns {null|TemplateResult} - set score template
   */
  setScore(side, setNum) {
    const uSide = ucFirst(side);
    const score = this.match[`scoreS${setNum}${uSide}`] !== null ?
      this.match[`scoreS${setNum}${uSide}`] : null;
    const tbScore = this.match[`scoreS${setNum}TB${uSide}`] !== null ?
      this.match[`scoreS${setNum}TB${uSide}`] : null;

    const classes = {
      [`${baseClass}__set-score`]: true,
      [`${baseClass}__set-score--${side}`]: true,
      [`${baseClass}__set-score--winner`]: this.match[`s${setNum}Winner`] === side,
      [`${baseClass}__set-score--live`]: this.match.state === State.LIVE &&
        setNum === this.match.currentSet,
      [`${baseClass}__set-score--last`]: setNum === this.match.currentSet,
      [`${baseClass}__set-score--2-digit`]: score && score > 9,
    };

    const tbScoreTemplate = tbScore !== null ?
      html`
        <span class="${baseClass}__tb-score">
          ${tbScore}
        </span>
      ` : '';

    return this.score(score, classes, tbScoreTemplate);
  }

  /**
   * Get full score template
   * @param {string} side - team side
   * @returns {string|TemplateResult} - full score template
   */
  fullScore(side) {
    const uSide = ucFirst(side);

    if (this.match[`score${uSide}`] === null) {
      return '';
    }

    const classes = {
      [`${baseClass}__set-score`]: true,
      [`${baseClass}__set-score--${side}`]: true,
      [`${baseClass}__set-score--winner`]: this.match.winner === side,
      [`${baseClass}__set-score--full`]: true,
    };

    return this.score(this.match[`score${uSide}`], classes);
  }

  /**
   * Get score template
   * @param {string} score - score value
   * @param {Object} [classes={}] - classes map
   * @param {string} [tbScore=''] - tie break score
   * @returns {TemplateResult} - score template
   */
  score(score, classes = {}, tbScore = '') {
    return html`
      <span class=${classMap(classes)}>
        ${score}
        ${tbScore}
      </span>
    `;
  }

  /**
   * Get team names template or '---' placeholder if team names are not defined
   * @param {string} side - team side
   * @returns {TemplateResult} - team names template
   */
  teamNames(side) {
    const names = this.match[`${side}TeamNames`];

    if (!names || !names.length) {
      return html`<span>---</span>`;
    }

    return html`
      ${repeat(
        names,
        (playerName) => playerName,
        (playerName, index) => html`${index > 0 ? ' / ' : ''}<span>${playerName}</span>`
      )}
    `;
  }

  /**
   * @inheritdoc
   */
  get matchRowClasses() {
    const classes = super.matchRowClasses;

    classes[`${baseClass}--sets-count-${this.match.numberOfSets}`] = true;

    return classes;
  }

  /**
   * Get middle content template
   * @returns {TemplateResult} - middle content template
   */
  get middleContent() {
    return html`
      <div class="${baseClass}__players-container">
        <span class=${classMap(this.teamClasses(Side.HOME))}>
          ${this.teamFlags(Side.HOME)}
          <span class="${baseClass}__team-name-text">
            ${this.teamNames(Side.HOME)}
          </span>
        </span>
        <span class=${classMap(this.teamClasses(Side.AWAY))}>
          ${this.teamFlags(Side.AWAY)}
          <span class="${baseClass}__team-name-text">
            ${this.teamNames(Side.AWAY)}
          </span>
        </span>
      </div>
      <div class="${baseClass}__sets-container">
        <span class="${baseClass}__set-scores ${baseClass}__set-scores--${Side.HOME}">
          ${this.fullScore(Side.HOME)}${this.setScores(Side.HOME)}
        </span>
        <span class="${baseClass}__set-scores ${baseClass}__set-scores--${Side.AWAY}">
          ${this.fullScore(Side.AWAY)}${this.setScores(Side.AWAY)}
        </span>
      </div>
    `;
  }

  /**
   * @inheritdoc
   */
  get content() {
    /* eslint-disable max-len */
    return html`
      <span class="${baseClass}__competition">
        <abbr class="${baseClass}__competition-code" title="${this.match.countryName} - ${this.match.competitionName}">${this.match.competitionCode}</abbr>
      </span>
      <span class="${baseClass}__start-time">${this.matchDate.format('HH:mm')}</span>
      <span class=${classMap(this.statusBoxClasses)}>${this.liveStatusText}</span>
      <div class="${baseClass}__middle">
        ${this.middleContent}
      </div>
      <a class=${classMap(this.iddaaBoxClasses)} href="#"
        data-iddaa-code="${this.match.iddaaCode}"
        data-url="${this.linker.toIddaaLayer(this.match)}"
        data-event-id="${this.match.id}"
      ></a>
      <a class=${classMap(this.favouriteClasses)} href="#"
        data-favourite-id="${this.match.id}"
        data-favourite-timestamp="${this.match.matchStartTime}"
      ></a>
    `;
    /* eslint-enable max-len */
  }
}
