import { pushEvent } from 'components/ga';
import { onRAF } from 'components/vendor/perform/utils';
import { show as showLoader, hide as hideLoader } from 'components/loader';
import ClickHandler from './click-handler';
import { log } from '../utils';

require('promise-polyfill/src/polyfill');
require('whatwg-fetch');

/**
 * Iddaa code click handler
 */
class IddaaButtonClick extends ClickHandler {
  /**
   * Favourite handler constructor
   * @param {HTMLElement} context - handler context
   */
  constructor(context) {
    super();
    this.context = context;
    this.buttonSelector = '[data-iddaa-code][data-url]';
    this.hiddenClass = 'match-row__iddaa-markets--hidden';
    this.openedLayers = {};
    this.renderedLayers = {};
    this.listeners = {};

    this.onClick = this.onClick.bind(this);
    this.onHideEnd = this.onHideEnd.bind(this);
    this.onShowEnd = this.onShowEnd.bind(this);

    this.context.addEventListener('click', this.onClick, false);
  }

  /**
   * Detach handler from context
   */
  detach() {
    this.context.removeEventListener('click', this.onClick, false);
  }

  /**
   * Click handler
   * @param {Event} event - event object
   */
  onClick(event) {
    const button = this.closest(event.target, this.buttonSelector);

    if (!button) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    this.toggleLayer(button.dataset);
  }

  /**
   * Open layer
   * @param {Object} settings - Iddaa layer settings
   */
  open(settings) {
    if (this.openedLayers[settings.iddaaCode]) {
      return;
    }

    pushEvent('LiveScoreMatches', 'IddaaClick', 'IddaaLayer');

    this.closeOthers(settings);
    this.openedLayers[settings.iddaaCode] = settings;

    const layerContainer = this.getLayerContainer(settings);
    const widgetWrapper = layerContainer.querySelector('.widget-iddaa-markets');

    widgetWrapper.innerHTML = '';
    layerContainer.style.height = '';
    layerContainer.classList.remove(this.hiddenClass);

    onRAF(() => {
      showLoader(layerContainer);

      const layerHeight = layerContainer.offsetHeight;

      this.getLayer(settings)
        .then(html => {
          widgetWrapper.innerHTML = html;

          const newLayerHeight = layerContainer.offsetHeight;

          hideLoader(layerContainer);

          if (layerHeight !== newLayerHeight) {
            layerContainer.style.height = `${layerHeight}px`;
            this.addTransitionEndListener(settings, this.onShowEnd);

            onRAF(() => {
              layerContainer.style.height = `${newLayerHeight}px`;
            });
          }

          $(this.context).find('.widget-iddaa-markets__expander').on('click', function(e) {
            const $this = $(this);
            $this.toggleClass('opened');
            $this.closest('div').siblings('.widget-iddaa-markets__market.type2').toggleClass('hidden');
          });
          $(this.context).find('.widget-iddaa-markets__market:not(.type2) .widget-iddaa-markets__option').on('click', function(e) {
            const $this = $(this);
            $this.closest('div').find('.widget-iddaa-markets__expander').toggleClass('opened');
            $this.closest('div').siblings('.widget-iddaa-markets__market.type2').toggleClass('hidden');
          });

        }).catch((...args) => {
          log('error', 'Iddaa layer error:', args);
        });
    });
  }

  /**
   * Close layer
   * @param {Object} settings - Iddaa layer settings
   */
  close(settings) {
    if (!this.openedLayers[settings.iddaaCode]) {
      return;
    }

    delete this.openedLayers[settings.iddaaCode];

    const layerContainer = this.getLayerContainer(settings);

    hideLoader(layerContainer);

    layerContainer.style.height = `${layerContainer.offsetHeight}px`;

    this.addTransitionEndListener(settings, this.onHideEnd);

    onRAF(() => {
      layerContainer.style.height = 0;
    });
  }

  /**
   * Add transition end event handler
   * @param {Object} settings - Iddaa layer settings
   * @param {Function} callback - callback to call when event fires
   */
  addTransitionEndListener(settings, callback) {
    const layerContainer = this.getLayerContainer(settings);

    this.removeTransitionEndListener(settings);

    this.listeners[settings.iddaaCode] = (event) => {
      if (event.target !== layerContainer || event.propertyName !== 'height') {
        return;
      }

      callback(settings);
    };

    layerContainer.addEventListener('transitionend', this.listeners[settings.iddaaCode], false);
  }

  /**
   * Remove transition end event handler
   * @param {Object} settings - Iddaa layer settings
   */
  removeTransitionEndListener(settings) {
    const layerContainer = this.getLayerContainer(settings);

    if (this.listeners[settings.iddaaCode]) {
      layerContainer
        .removeEventListener('transitionend', this.listeners[settings.iddaaCode], false);
    }
  }

  /**
   * Hide transition end handler
   * @param {Object} settings - Iddaa layer settings
   */
  onHideEnd(settings) {
    const layerContainer = this.getLayerContainer(settings);

    if (!layerContainer.offsetHeight) {
      layerContainer.classList.add(this.hiddenClass);
      layerContainer.style.height = '';
    }

    this.removeTransitionEndListener(settings);
  }

  /**
   * Show transition end handler
   * @param {Object} settings - Iddaa layer settings
   */
  onShowEnd(settings) {
    const layerContainer = this.getLayerContainer(settings);

    if (layerContainer.offsetHeight) {
      layerContainer.style.height = '';
    }

    this.removeTransitionEndListener(settings);
  }

  /**
   * Close all other opened Iddaa layers
   * @param {Object} settings - Iddaa layer settings
   */
  closeOthers(settings) {
    Object.keys(this.openedLayers)
      .filter(iddaaCode => iddaaCode !== settings.iddaaCode)
      .forEach(iddaaCode => {
        this.close(this.openedLayers[iddaaCode]);
      });
  }

  /**
   * Toggle layer
   * @param {Object} settings - Iddaa layer settings
   */
  toggleLayer(settings) {
    if (this.openedLayers[settings.iddaaCode]) {
      this.close(settings);
    } else {
      this.open(settings);
    }
  }

  /**
   * Get layer container
   * @param {Object} settings - Iddaa layer settings
   * @returns {HTMLElement} - layer container
   */
  getLayerContainer(settings) {
    return this.context.querySelector(`[data-for-code="${settings.iddaaCode}"]`);
  }

  /**
   * Render Iddaa Layer by fetching it or putting cached content
   * @param {Object} settings - Iddaa layer settings
   * @returns {Promise} - Iddaa layer promise
   */
  getLayer(settings) {
    if (this.renderedLayers[settings.iddaaCode]) {
      return this.renderedLayers[settings.iddaaCode];
    }

    this.renderedLayers[settings.iddaaCode] = new Promise((resolve, reject) => {
      const layerContainer = this.getLayerContainer(settings);
      fetch(settings.url)
        .then(response => response.json())
        .then(response => {
          if (response.status === 'success') {
            resolve(response.data.html);
          } else {
            log('error', 'Iddaa layer fetch error', response);
            reject(response);
            delete this.renderedLayers[settings.iddaaCode];
          }
        })
        .catch(() => {
          delete this.renderedLayers[settings.iddaaCode];
        })
        .finally(() => {
          hideLoader(layerContainer);
        });
    });

    return this.renderedLayers[settings.iddaaCode];
  }

  /**
   * Clear Iddaa layer state
   */
  clear() {
    Object.keys(this.listeners).forEach(iddaaCode => {
      if (this.openedLayers[iddaaCode]) {
        this.onHideEnd(this.openedLayers[iddaaCode]);
      }
    });

    this.listeners = {};
    this.openedLayers = {};
    this.renderedLayers = {};
  }
}

export default (...args) => new IddaaButtonClick(...args);
