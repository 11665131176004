import { local as localStorage } from 'components/vendor/perform/storage';
import moment from 'moment';
import { FavouriteFilter } from '../filter/favourite'; // eslint-disable-line no-unused-vars

/**
 * Favourite click handler
 */
export default class Favourite {
  /**
   * Favourite handler constructor
   * @param {HTMLElement} context - handler context
   * @param {Function} callback - update callback
   * @param {FavouriteFilter} filter - filter to update
   * @param {string} [localStorageKeyName=favouriteMatches] - key name where to save favourites
   * @param {number} [ttl=86400000] - time to live for saved favourites in ms (default 24 hours)
   * @param {string} [toggleClass=match-row__favourite--enabled] - class name that should be set
   * if element is set as favourite, set false to disable
   */
  constructor(
    context,
    callback,
    filter,
    localStorageKeyName = 'favouriteMatches',
    ttl = 86400000,
    toggleClass = 'match-row__favourite--enabled'
  ) {
    this.context = context;
    this.localStorageKeyName = localStorageKeyName;
    this.ttl = ttl;
    this.callback = callback;
    this.filter = filter;
    this.filter.favourites = this.favourites;
    this.toggleClass = toggleClass;

    this.onClick = this.onClick.bind(this);

    this.context.addEventListener('click', this.onClick, false);
  }

  /**
   * Detach handler from context
   */
  detach() {
    this.context.removeEventListener('click', this.onClick, false);
  }

  /**
   * Click handler
   * @param {Event} event - event object
   */
  onClick(event) {
    if (!event.target.matches('[data-favourite-id]')) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    const id = event.target.dataset.favouriteId;
    const timestamp = event.target.dataset.favouriteTimestamp ?
      Number(event.target.dataset.favouriteTimestamp) : null;
    if (id) {
      const isSet = this.toggleFavourite(id, timestamp);

      if (this.toggleClass) {
        event.target.classList.toggle(this.toggleClass, isSet);
      }
    }
  }

  /**
   * Toggle favourite
   * @param {string} id - favourite id
   * @param {number} [timestamp=null] - timestamp to check if it is from today
   * @returns {boolean} - true if element was set as favourite, false otherwise
   */
  toggleFavourite(id, timestamp = null) {
    const favourites = this.favourites;
    if (favourites[id]) {
      delete favourites[id];
    } else {
      favourites[id] = timestamp || true;
    }

    this.store(favourites);
    this.callback();

    return typeof favourites[id] !== 'undefined';
  }

  /**
   * Save favourites to local storage
   * @param {Object} favourites - new dictionary to save
   */
  store(favourites) {
    localStorage.set(this.localStorageKeyName, favourites, this.ttl);
    this.filter.favourites = favourites;
  }

  /**
   * Get saved favourites
   * @returns {Object} - dictionary of favourite ids
   */
  get favourites() {
    const favourites = localStorage.get(this.localStorageKeyName) || {};
    const dayBeforeInTimestamp = moment().subtract(1, 'day').valueOf();

    Object.keys(favourites).forEach((id) => {
      if (favourites[id] === true || favourites[id] > dayBeforeInTimestamp) {
        return;
      }

      delete favourites[id];
    });

    this.store(favourites);

    return favourites;
  }
}
