import pubsub from 'pubsub.js';
import {
  isSupported as visibilityIsSupported,
  change as visibilityChange,
} from 'visibilityjs';

import { show as showLoader, hide as hideLoader } from 'components/loader';
import { getSetting } from 'components/vendor/perform/localization';
import { pubsubNamespace, getDeviceInfo } from 'components/vendor/perform/utils';
import lazyLoader from 'components/lazy-loader';

import oddsLinksChecker from 'widgets/iddaa';

import getVariant from './variant/get';

import FiltersState from './filter/state';
import LiveFilter from './filter/live';
import IddaaFilter from './filter/iddaa';
import FavouriteFilter from './filter/favourite';
import SportFilter from './filter/sport';
import LiveBettingFilter from './filter/live-betting';
import LiveOrFixtureFilter from './filter/live-or-fixture';

import sortFactory from './sort/factory';

import FavouriteHandler from './handlers/favourite';
import PubsubHandler from './handlers/pubsub';
import KeyEventsSoundHandler from './handlers/key-events-sound';
import matchRowClickHandler from './handlers/match-row-click';
import iddaaButtonClickHandler from './handlers/iddaa-button-click';

import renderList from './renderer/list';
import renderEmpty from './renderer/empty';

import { log, debounce, performanceAPI, formatDate, setEnv } from './utils';

require('@webcomponents/template');

/**
 * Competition Livescore module
 * @param {Object} context - context object
 * @param {Object} settings - module settings
 */
export default function (context, settings) {
  const variantConfig = getVariant(settings.variant);
  const eventNamespace = pubsubNamespace(context);
  const blockClass = 'widget-livescore';
  const stateUpdateMark = 'livescore_state_update';
  const liveOrFixtureFilter = new LiveOrFixtureFilter();
  const isMobileDevice = (getDeviceInfo().device.type || 'desktop') !== 'desktop';
  const adSlots = context.querySelectorAll('.widget-gpt-ads');

  context.innerHTML = '';

  let currentDate = new Date(`${settings.asyncRequestParams.matchDate}T00:00:00+00:00`);
  let data = null;
  let sorter = variantConfig.sorter;
  let providersStarted = false;
  let isDateChange = false;
  let isVisible = true;
  let lastUpdate = 0;
  let favouriteEnabled = true;

  setEnv(getSetting('common.app.env'));
  oddsLinksChecker(context);

  settings.asyncRequestParams.matchDate = formatDate(currentDate);

  const filtersState = new FiltersState();
  const buttonFilterStates = {};
  const pubsubHandler = new PubsubHandler(context, onStateUpdate, pubsub, eventNamespace);
  const iddaaFilter = new IddaaFilter();
  const liveBettingFilter = new LiveBettingFilter();

  [iddaaFilter, liveBettingFilter].forEach(filter => {
    const state = new FiltersState();
    state.addFilter(filter);
    buttonFilterStates[filter.name] = {
      filter,
      state,
      enabled: false,
    };

    pubsubHandler.addHandler(`${filter.name}-change`, (value, savedState) => {
      filtersState.toggleFilter(filter, value);
      buttonFilterStates[filter.name].enabled = savedState || value;
    });

    pubsub.publish(`${eventNamespace}/${filter.name}-state-change`, [context, false]);
  });

  variantConfig.filters.forEach(filter => {
    filtersState.addFilter(filter);

    Object.keys(buttonFilterStates).forEach(filterName => {
      buttonFilterStates[filterName].state.addFilter(filter);
    });
  });

  context.classList.add(`${blockClass}--${sorter.name}`);

  const sportFilter = new SportFilter();
  sportFilter.setSports(variantConfig.defaultSports);
  filtersState.addFilter(sportFilter);

  pubsubHandler.addHandler('sport-change', (sports) => {
    sportFilter.setSports(sports);
  });

  const liveFilter = new LiveFilter();

  pubsubHandler.addHandler('live-now-change', (value) => {
    filtersState.toggleFilter(liveFilter, value);
  });

  pubsubHandler.addHandler('order-change', (value) => {
    context.classList.remove(`${blockClass}--${sorter.name}`);
    sorter = sortFactory(value);
    context.classList.add(`${blockClass}--${sorter.name}`);
  });

  const favouriteFilter = new FavouriteFilter();
  const favouriteHandler = new FavouriteHandler(context, onStateUpdate, favouriteFilter);

  pubsubHandler.addHandler('favourite-change', (value) => {
    filtersState.toggleFilter(favouriteFilter, value);
  });

  const iddaaButtonHandler = iddaaButtonClickHandler(context);

  const odDateChange = debounce((value) => {
    settings.asyncRequestParams.matchDate = value;
    currentDate = new Date(value);

    startProviders();

    isDateChange = false;
  }, 500);

  pubsubHandler.addHandler('before-date-change', () => {
    isDateChange = true;
  }, settings.asyncRequestParams.matchDate, false);

  pubsubHandler.addHandler('date-change', (value) => {
    // Disable buttons on date change
    Object.keys(buttonFilterStates).forEach(filterName => {
      pubsub.publish(`${eventNamespace}/${filterName}-state-change`, [context, false]);
    });

    odDateChange(value);
  }, settings.asyncRequestParams.matchDate, false);

  let keyEventsSoundHandler;

  if (variantConfig.keyEventsSounds) {
    keyEventsSoundHandler = new KeyEventsSoundHandler();

    pubsubHandler.addHandler('sound-change', (value) => {
      if (value) {
        keyEventsSoundHandler.unmute();
      } else {
        keyEventsSoundHandler.mute();
      }
    }, null, false);
  }

  pubsubHandler.addHandler('set-favourite-state', (value) => {
    favouriteEnabled = value;
  }, favouriteEnabled, false);

  const dataWorker = new Worker('widgets/livescore/data.worker', {
    name: 'livescoreDataWorker',
    type: 'module', // This need to be at the end :)
  });
  dataWorker.addEventListener('message', (message) => {
    const newData = message.data;
    if (newData.state === 'data') {
      log('info', 'New data from worker', newData);
      onStateUpdate(newData);
    }
  });

  dataWorker.postMessage({
    action: 'setEnv',
    env: getSetting('common.app.env'),
  });

  /**
   * State update handler
   * @param {Object} newData - new data
   */
  function onStateUpdate(newData = null) {
    const firstRender = !data;

    if (newData) {
      data = newData;
    }

    if (!data || isDateChange) {
      return;
    }

    performanceAPI.startMark(stateUpdateMark);

    lastUpdate = Object.keys(data.data)
      .map(key => data.data[key].lastUpdated)
      .reduce(
        (prevLastUpdate, matchLastUpdate) => Math.max(matchLastUpdate, prevLastUpdate),
        lastUpdate
      );

    if (firstRender) {
      const liveOrFixtureMatches = liveOrFixtureFilter.apply(data.data, data.competitions);

      if (!Object.keys(liveOrFixtureMatches).length) {
        stopProviders();
      } else if (lastUpdate) {
        dataWorker.postMessage({
          action: 'call',
          provider: 'socket',
          functionName: 'eventsList',
          args: [
            lastUpdate,
          ],
        });
      }
    }

    Object.keys(buttonFilterStates).forEach(buttonName => {
      const { state, enabled, filter } = buttonFilterStates[buttonName];
      const hasValues = Object.keys(state.apply(data.data, data.competitions)).length > 0;

      // Re-enable button when there are matches matching filter
      pubsub.publish(`${eventNamespace}/${buttonName}-state-change`, [context, hasValues]);

      filtersState.toggleFilter(filter, hasValues && enabled);
    });

    const matchesList = sorter.apply(
      filtersState.apply(data.data, data.competitions),
      data.competitions
    );

    if (!matchesList.length) {
      const isIddaaFiltred = filtersState.hasFilter(iddaaFilter);
      const isFavouriteFiltred = filtersState.hasFilter(favouriteFilter);
      const isLiveFiltred = filtersState.hasFilter(liveFilter);
      const isLiveBetFiltered = filtersState.hasFilter(liveBettingFilter);
      let message = settings.translations.noGames;

      if (isIddaaFiltred && !isFavouriteFiltred && isLiveFiltred) {
        message = settings.translations.noLiveIddaaGames;
      } else if (!isIddaaFiltred && isFavouriteFiltred && !isLiveFiltred) {
        message = settings.translations.noFavouritedGames;
      } else if (isLiveBetFiltered) {
        message = settings.translations.noLiveBettingGames;
      }

      renderEmpty(context, {
        message,
        adSlots,
      });
    } else {
      log('info', `Matches to render: ${matchesList.length}`, matchesList);

      renderList(context, matchesList, {
        translations: settings.translations,
        favourites: favouriteHandler.favourites,
        showCompetitionHeaders: sorter.name === 'comp',
        keyEvents: data.keyEvents,
        urlTemplates: settings.urlTemplates,
        adSlots,
        selectedDate: currentDate,
        favouriteEnabled,
      });
      lazyLoader().observe();
      context.classList.remove(`${blockClass}--empty`);

      if (!firstRender && keyEventsSoundHandler && (isVisible || !isMobileDevice)) {
        keyEventsSoundHandler.handleEvents(data.keyEvents);
      }
    }

    hideLoader(context);

    performanceAPI.endMark(stateUpdateMark);
  }

  /**
   * Init data providers in data worker
   * @param {Object} providers - providers settings
   */
  function initDataProviders(providers) {
    showLoader(context);

    data = null;
    lastUpdate = null;

    dataWorker.postMessage({
      action: 'stateOptions',
      options: {
        updateData: {
          translations: settings.translations,
        },
      },
    });

    dataWorker.postMessage({
      action: 'start',
      providers,
    });

    providersStarted = true;
  }

  /**
   * Start providers
   */
  function startProviders() {
    iddaaButtonHandler.clear();

    const providers = {
      sdapi: {
        asyncRequestParams: settings.asyncRequestParams,
        url: settings.urlJson,
      },
      sdapiUpdate: {
        asyncRequestParams: settings.asyncRequestParams,
        url: settings.urlUpdateJson,
        refreshDelay: 60000, // Update every one minute
        hasEnoughData: false,
      },
    };

    if (getSetting('common.middleware.socket.enabled')) {
      providers.socket = {
        tokenUrl: getSetting('common.middleware.api.tokenUrl'),
        translations: settings.translations,
        throttle: 1000, // Collect events and send them once per 1 second
        hasEnoughData: false,
        socketUrl: settings.socketUrl,
      };
    }

    initDataProviders(providers);
  }

  /**
   * Stop providers
   */
  function stopProviders() {
    if (providersStarted) {
      dataWorker.postMessage({ action: 'stop' });
      providersStarted = false;
    }
  }

  /**
   * When page start to be visible checks last update time, if time passed from last update is
   * larger or equal to 1 minute restarts providers to fetch actual data
   */
  function onVisible() {
    isVisible = true;

    if (!lastUpdate) {
      return;
    }

    if (Date.now() - lastUpdate >= 60000) {
      startProviders();
    }
  }

  /**
   * Page visibility change handler for hidden state
   */
  function onHidden() {
    isVisible = false;
    lastUpdate = Date.now();
  }

  pubsub.subscribeOnce('core/rendered', () => {
    pubsub.publish(`${eventNamespace}/set-defaults`, [
      context,
      sorter.name,
      false,
      false,
      false,
      false,
    ]);
  });

  startProviders();
  matchRowClickHandler(context);

  if (visibilityIsSupported()) {
    visibilityChange((event, state) => {
      if (state === 'visible') {
        onVisible();
      } else {
        onHidden();
      }
    });
  } else {
    window.addEventListener('focus', onVisible, false);
    window.addEventListener('blur', onHidden, false);
  }

  window.livescore = {
    testData: (testData, competitions = null) => {
      stopProviders();

      dataWorker.postMessage({
        action: 'testData',
        data: testData,
        competitions,
      });
    },
    dumpData: () => data,
    setEnv: (env) => {
      setEnv(env);
      dataWorker.postMessage({
        action: 'setEnv',
        env,
      });
    },
  };
}
