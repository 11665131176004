import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map.js';
import moment from 'moment';
import MatchStatus from '../../../match/status';
import MatchState from '../../../match/state';
import periodIdToStart from '../../../match/period-id/soccer/to-start';
import periodIdToDuration from '../../../match/period-id/soccer/to-duration';

export const baseClass = 'match-row';
const eventClass = `${baseClass}--event`;

const eventsDuration = {
  goals: 7500,
  'red-cards': 7000,
};

const eventsInProgress = {};

/**
 * Base match row
 */
export default class MatchRow {
  /**
   * Match row constructor
   * @param {Object} row - match row data
   * @param {Object} row.data - match data
   * @param {string} row.id - row id
   * @param {boolean} row.even - even flag
   * @param {boolean} row.first - is first flag
   * @param {boolean} row.last - is last flag
   * @param {Object} linker - linker
   * @param {Object} data - custom data
   * @param {Object} data.translations - translations map
   * @param {Object} data.favourites - favourites map
   * @param {Object} data.keyEvents - key events map
   * @param {boolean} data.favouriteEnabled - favourite enabled flag
   */
  constructor(
    { data: match, id, even, first, last },
    linker,
    { translations, favourites, keyEvents, favouriteEnabled }
  ) {
    this.id = id;
    this.even = even;
    this.first = first;
    this.last = last;
    this.match = match;
    this.linker = linker;
    this.translations = translations;
    this.favourites = favourites;
    this.keyEvents = keyEvents;
    this.favouriteEnabled = favouriteEnabled;
    this.matchDate = moment(match.matchStartTime);
  }

  /**
   * Register new event start time
   * @param {string} eventName - event name
   * @param {string} side - team side
   */
  addEventStartTime(eventName, side) {
    let eventPlaceholder = eventsInProgress;
    [this.match.id, eventName, side].forEach(key => {
      if (!eventPlaceholder[key]) {
        eventPlaceholder[key] = {};
      }

      eventPlaceholder = eventPlaceholder[key];
    });

    eventsInProgress[this.match.id][eventName][side] = Date.now();
  }

  /**
   * Get team classes map
   * @param {string} side - team side
   * @returns {Object} - classes map
   */
  teamClasses(side) {
    return {
      [`${baseClass}__team-name`]: true,
      [`${baseClass}__team-name--${side}`]: true,
      [`${baseClass}__team-name--winner`]: this.match.winner === side,
      [`${baseClass}__team-name--advancing`]: this.match.advancingTeam === side,
    };
  }

  /**
   * Get live status text
   * @returns {string|TemplateResult} - live status text template or string
   */
  get liveStatusText() {
    if (!this.match.periodMinute || this.match.substate === MatchStatus.SUSPENDED) {
      return this.match.statusBoxContent;
    }

    const periodDuration = periodIdToDuration(this.match.periodId);
    const periodStart = periodIdToStart(this.match.periodId);

    if (periodDuration === null || periodStart === null) {
      return this.match.statusBoxContent;
    }

    const rest = this.match.periodMinute > periodDuration ?
      this.match.periodMinute - periodDuration : 0;
    const periodTime = !rest ? periodStart + this.match.periodMinute : periodStart + periodDuration;
    const restHtml = !rest ? '' : html`+<span class="${baseClass}__add-time">${rest}</span>`;

    return html`${periodTime}<span class="${baseClass}__minutes">'</span>${restHtml}`;
  }

  /**
   * Get iddaa markets template
   * @returns {string|TemplateResult} - iddaa markets template or empty string
   */
  get iddaaMarkets() {
    if (!this.match.iddaaCode) {
      return '';
    }

    return html`
      <div class="${baseClass}__iddaa-markets ${baseClass}__iddaa-markets--hidden"
        data-for-code="${this.match.iddaaCode}"
      >
        <div class="widget-iddaa-markets widget-iddaa-markets--main"></div>
      </div>
    `;
  }

  /**
   * Get match row classes map
   * @returns {Object} - match row classes map
   */
  get matchRowClasses() {
    const classes = {
      [baseClass]: true,
      [`${baseClass}--even`]: this.even,
      [`${baseClass}--last`]: this.last,
      [`${baseClass}--first`]: this.first,
      [`${baseClass}--${this.match.state}`]: true,
      [`${baseClass}--sport-${this.match.sportType.toLowerCase()}`]: true,
    };

    if (this.match.status !== MatchStatus.LIVE && this.match.substate) {
      classes[`${baseClass}--${this.match.substate}`] = true;
    }

    if (eventsInProgress[this.match.id]) {
      Object.keys(eventsInProgress[this.match.id]).forEach(eventName => {
        Object.keys(eventsInProgress[this.match.id][eventName]).forEach(side => {
          const startTime = eventsInProgress[this.match.id][eventName][side];
          if (Date.now() - startTime > eventsDuration[eventName]) {
            delete eventsInProgress[this.match.id][eventName][side];
          } else {
            classes[`${eventClass}-${eventName}`] = true;
            classes[`${eventClass}-${eventName}-${side}`] = true;
          }
        });
      });
    }

    if (this.match.status === MatchStatus.LIVE) {
      Object.keys(this.keyEvents).forEach(eventName => {
        const keyEvent = this.keyEvents[eventName][this.match.id];
        if (!keyEvent) {
          return;
        }

        classes[`${eventClass}-${eventName}`] = true;
        Object.keys(keyEvent).forEach(side => {
          if (keyEvent[side] > 0) {
            classes[`${eventClass}-${eventName}-${side}`] = true;

            this.addEventStartTime(eventName, side);
          }
        });
      });
    }

    return classes;
  }

  /**
   * Get status box classes map
   * @returns {Object} - status box classes map
   */
  get statusBoxClasses() {
    return {
      [`${baseClass}__status`]: true,
      [`${baseClass}__status--live-bet`]:
      this.match.liveBetting && this.match.state !== MatchState.POST,
    };
  }

  /**
   * Get iddaa box classes map
   * @returns {Object} - iddaa box classes map
   */
  get iddaaBoxClasses() {
    return {
      [`${baseClass}__iddaa`]: true,
      [`${baseClass}__iddaa--hidden`]: this.match.iddaaCode === null,
      [`${baseClass}__iddaa--mbc-one`]: Number(this.match.mbc) === 1,
    };
  }

  /**
   * Get favourite classes map
   * @returns {Object} - favourite classes map
   */
  get favouriteClasses() {
    return {
      [`${baseClass}__favourite`]: true,
      [`${baseClass}__favourite--enabled`]: Boolean(this.favourites[this.match.id]),
      [`${baseClass}__favourite--hidden`]: !this.favouriteEnabled,
    };
  }

  /**
   * Get match url
   * @returns {string} - match url
   */
  get matchUrl() {
    return this.linker.toMatch(this.match);
  }

  /**
   * Get score label
   * @returns {string} - score label
   */
  get scoreLabel() {
    return '';
  }

  /**
   * Get before content template
   * @returns {string|TemplateResult} - template result or empty string
   */
  get beforeContent() {
    return '';
  }

  /**
   * Get content template
   * @returns {string|TemplateResult} - template result or empty string
   */
  get content() {
    return '';
  }

  /**
   * Get after content template
   * @returns {string|TemplateResult} - template result or empty string
   */
  get afterContent() {
    return '';
  }

  /**
   * Render match row
   * @returns {TemplateResult} - match row template result
   */
  render() {
    return html`
      <div class=${classMap(this.matchRowClasses)} id="${this.id}"
        data-match-id="${this.match.id}" data-rb-id="${this.match.rbId}"
        data-match-date="${this.matchDate.format('YYYY-MM-DD HH:mm')}">
        ${this.beforeContent}
        <div class="${baseClass}__match-content"
            data-sport="${this.match.sportType}"
            data-match-url="${this.matchUrl}"
            data-score-label="${this.scoreLabel}">
          ${this.content}
        </div>
        ${this.afterContent}
        ${this.iddaaMarkets}
      </div>
    `;
  }
}
