import { html } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map.js';
import MatchRow, { baseClass } from './base';
import Side from '../../../match/side';
import flag from '../flag';

/**
 * Default match row
 */
export default class Default extends MatchRow {
  /**
   * Get half time score template
   * @returns {TemplateResult} - half time score template
   */
  get halfTimeScore() {
    const hasHtScore = this.match.scoreHtHome !== null && this.match.scoreHtAway !== null;
    const htScore = hasHtScore ?
      `${this.match.scoreHtHome}-${this.match.scoreHtAway}` : ''
    ;
    const htScoreClass = hasHtScore ? '' : ` ${baseClass}__half-time-score--hidden`;

    return html`
      <div class="${baseClass}__half-time-score${htScoreClass}">
        ${this.translations.halfTime} ${htScore}
      </div>
    `;
  }

  /**
   * Get middle content template
   * @returns {TemplateResult} - middle content template
   */
  get middleContent() {
    /* eslint-disable max-len */
    return html`
      <div class="${baseClass}__middle-container">
        <a href="${this.linker.toTeam(this.match, Side.HOME)}" data-jsblank="true"
          class=${classMap(this.teamClasses(Side.HOME))}
        >
          <img width="18" height="18" class="${baseClass}__team-crest ${baseClass}__team-crest--home"
            data-src="${this.linker.toCrest(this.match, Side.HOME)}"
            alt="${this.match.homeTeamName}"
          >
          <span class="${baseClass}__team-name-text">
            ${this.match.homeTeamName}
          </span>
        </a>
        <a class="${baseClass}__score" data-jsblank="true" href="${this.matchUrl}">
          <span class="${baseClass}__score-home">${this.match.scoreHome}</span>-<span class="${baseClass}__score-away">${this.match.scoreAway}</span>
        </a>
        <a href="${this.linker.toTeam(this.match, Side.AWAY)}" data-jsblank="true"
          class=${classMap(this.teamClasses(Side.AWAY))}
        >
          <img width="18" height="18" class="${baseClass}__team-crest ${baseClass}__team-crest--away"
            data-src="${this.linker.toCrest(this.match, Side.AWAY)}"
            alt="${this.match.awayTeamName}"
          >
          <span class="${baseClass}__team-name-text">
            ${this.match.awayTeamName}
          </span>
        </a>
      </div>
    `;
    /* eslint-enable max-len */
  }

  /**
   * @inheritdoc
   */
  get content() {
    return html`
      <a class="${baseClass}__competition" data-jsblank="true"
        href="${this.linker.toCompetition(this.match)}">
        <span class="${baseClass}__competition-flag">
          ${flag(this.match, this.linker)}
        </span>
        <abbr class="${baseClass}__competition-code"
            title="${this.match.competitionName}">${this.match.competitionCode}</abbr>
      </a>
      <a class="${baseClass}__start-time"
        data-jsblank="true"
        href="${this.matchUrl}">${this.matchDate.format('HH:mm')}</a>
      <a class=${classMap(this.statusBoxClasses)}
        data-jsblank="true"
        href="${this.matchUrl}">${this.liveStatusText}</a>
      <div class="${baseClass}__middle">
        ${this.middleContent}
      </div>
      ${this.halfTimeScore}
      <a class=${classMap(this.iddaaBoxClasses)} href="#"
        data-iddaa-code="${this.match.iddaaCode}"
        data-url="${this.linker.toIddaaLayer(this.match)}"
        data-event-id="${this.match.id}"
      ></a>
      <a class=${classMap(this.favouriteClasses)} href="#"
        data-favourite-id="${this.match.id}"
        data-favourite-timestamp="${this.match.matchStartTime}"
      ></a>
    `;
  }
}
