import { html } from 'lit-html';

export const UNKNOWN_ID = 'unknown';
const onError = (linker, match) =>
  (event) => {
    const { target: img } = event;
    img.src = linker.toFlag(match, false, UNKNOWN_ID);
    img.srcset = linker.toFlag(match, true, UNKNOWN_ID);
  };

export default (match, linker, customClasses = [], countryId = null, countryName = null) =>
  html`
    <img class="flag-18x18${customClasses.length ? ` ${customClasses.join(' ')}` : ''}"
      data-srcset="${linker.toFlag(match, true, countryId)}"
      data-src="${linker.toFlag(match, false, countryId)}"
      alt="${countryName || match.countryName}"
      title="${countryName || match.countryName}"
      @error=${onError(linker, match)}
    >
  `;
