const immutableKeys = [
  'id',
  'competitionId',
  'competitionKey',
  'competitionName',
  'competitionCode',
  'competitionSlug',
  'competitionType',
  'seasonSlug',
  'seasonId',
  'countryId',
  'countryName',
  'countrySlug',
  'sportType',
  'homeTeamId',
  'homeTeamName',
  'homeTeamSlug',
  'awayTeamId',
  'awayTeamName',
  'awayTeamSlug',
  'matchName',
  'matchSlug',
];

const dataKeys = immutableKeys.concat([
  'advancingTeam',
  'rbId',
  'matchStartTime',
  'periodId',
  'periodStart',
  'lastUpdated',
  'state',
  'status',
  'substate',
  'statusBoxContent',
  'winner',
  'iddaaCode',
  'liveBetting',
  'mbc',
  'scoreHome',
  'scoreAway',
  'stageId',
  'stageName',
  'seriesId',
  'seriesName',
  'sortOrder',
]);

/**
 * Base match entity class
 */
export default class Base {
  /**
   * List of immutable keys (should not change during update)
   * @returns {Array.<string>} - array of data keys
   */
  static get immutableKeys() {
    return immutableKeys.slice();
  }

  /**
   * Data keys of match entity
   * @returns {Array.<string>} - array of data keys
   */
  get dataKeys() { // eslint-disable-line class-methods-use-this
    return dataKeys.slice();
  }

  /**
   * Match entity constructor
   * @param {Object} data - data to be injected into entity
   */
  constructor(data) {
    this.dataKeys.forEach((key) => {
      this[key] = typeof data[key] !== 'undefined' ? data[key] : null;
    });
  }

  /**
   * Periodic entity update callback
   * @param {Object} data - additional data
   * @returns {boolean} - true if update changed anything
   */
  update(data) { // eslint-disable-line class-methods-use-this, no-unused-vars
    return false;
  }
}
