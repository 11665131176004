export default {
  PRE_GAME: 0,
  FIRST_HALF: 1,
  SECOND_HALF: 2,
  FIRST_QUARTER: 3,
  SECOND_QUARTER: 4,
  THIRD_QUARTER: 5,
  FOURTH_QUARTER: 6,
  OVERTIME: 7,
  QUARTER_FIRST_BREAK: 11,
  HALF_TIME_BREAK: 12,
  QUARTER_SECOND_BREAK: 13,
  OVERTIME_TO_BE_PLAYED: 14,
  POST_GAME: 15,
};
