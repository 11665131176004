import Base from './base';
import { performanceAPI } from '../utils';
import factory from './factory';

/**
 * Competition order sorter
 */
export default class Competition extends Base {
  /**
   * Competition sorter constructor
   */
  constructor() {
    super('comp');
    this.kickoffTime = factory('time');
  }

  /**
   * @inheritdoc
   */
  apply(data, competitions) { // eslint-disable-line class-methods-use-this
    const performanceMarkName = 'livescore_competition_sort';

    performanceAPI.startMark(performanceMarkName);

    const sorted = Object.keys(data)
      .map(key => data[key])
      .sort((matchA, matchB) => {
        if (matchA.sportType == 'S' && matchB.sportType != 'S') return -1;
        if (matchA.sportType != 'S' && matchB.sportType == 'S') return 1;
        return (matchA.sortOrder >= 0 ? matchA.sortOrder : competitions.indexOf(matchA.competitionKey)) - (matchB.sortOrder >= 0 ? matchB.sortOrder : competitions.indexOf(matchB.competitionKey))
      });
    performanceAPI.endMark(performanceMarkName);

    return sorted;
  }
}
