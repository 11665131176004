import Base from './base'; // eslint-disable-line no-unused-vars
import Full from './full';
import Snippet from './snippet';

/**
 * Get variant configuration
 * @param {string} name - name of variant
 * @returns {Base} - configuration
 */
export default function (name) {
  switch (name) {
    case 'snippet':
      return new Snippet();
    case 'full':
    default:
      return new Full();
  }
}
