import start from 'assets/js/main';
import pubsub from 'pubsub.js';
import { pubsubNamespace } from 'components/vendor/perform/utils';

require('pages/homepage/index/style.scss');
require('pages/homepage/index/ga-events');

require('widgets/livescore');
require('widgets/livescore/style.scss');
require('p0compLocal/competition/tables');
require('p0compLocal/competition/tables/style.scss');

require('widgets/dropdown');
require('widgets/section-title-bar/style.scss');
require('widgets/social/post');
require('widgets/article/top-news');
require('widgets/article/top-video');
require('widgets/popular-comments');

const selectorPage = '.page-homepage-index';
const selectorCompetitionSelectorContainer = `${selectorPage}__table-dropdown`;
const selectorDropdown = '.component-dropdown';
const selectorCompetitionTables = '.p0c-competition-tables';

const $context = $(selectorPage);
const $competitionSelectorContainer = $context.find(selectorCompetitionSelectorContainer);
const $competitionSelector = $competitionSelectorContainer.find(selectorDropdown);
const competitionSelectorSettings = $competitionSelector.data('settings');
const competitionSelectorContext = $competitionSelector.get(0);
const competitionTablesContext = $context.find(selectorCompetitionTables).get(0);

const dropdownNamespace = pubsubNamespace(competitionSelectorContext);
const competitionTablesNamesapce = pubsubNamespace(competitionTablesContext);

pubsub.subscribe(`${dropdownNamespace}/change`, (eventContext, seasonId) => {
  if (competitionSelectorContext !== eventContext) {
    return;
  }

  pubsub.publish(`${dropdownNamespace}/set-value`, [competitionSelectorContext, seasonId]);
  pubsub.publish(`${competitionTablesNamesapce}/competition-change`, [competitionTablesContext, {
    id: competitionSelectorSettings.SeasonIdToCompetitionIdMapping[seasonId],
  }]);
  pubsub.publish(`${competitionTablesNamesapce}/season-change`, [competitionTablesContext, {
    id: seasonId,
  }]);
});

pubsub.subscribeOnce('core/rendered', () => {
  const seasonId = $competitionSelector.find('[selected]').val();
  pubsub.publish(`${dropdownNamespace}/set-value`, [competitionSelectorContext, seasonId]);
});

start();
