import Base from './base';
import MatchState from '../match/state';
import MatchStatus from '../match/status';

/**
 * Not updated filter
 */
export default class NotUpdated extends Base {
  /**
   * @inheritdoc
   */
  constructor() {
    super('not-updated');
    this.timeLimit = 15 * 60000; // 15 minutes
  }

  /**
   * @inheritdoc
   */
  apply(data) {
    const time = Date.now();

    // Remove all matches without start time and without any update after time limit after
    // start time
    return Object.keys(data)
      .map(key => [data[key], key])
      .filter(([entity]) => {
        switch (entity.substate) {
          case MatchStatus.SUSPENDED:
          case MatchStatus.POSTPONED:
          case MatchStatus.CANCELED:
            return true;
          default:
            return entity.matchStartTime && (
              entity.state !== MatchState.PRE ||
              time - entity.matchStartTime <= this.timeLimit
            );
        }
      })
      .reduce((dict, [entity, key]) => {
        dict[key] = entity;
        return dict;
      }, {});
  }
}
