import Base from './base';
import Simple from './simple';
import MatchState from '../match/state';
import MatchStatus from '../match/status';

/**
 * Live games filter
 */
export default class Live extends Base {
  /**
   * @inheritdoc
   */
  constructor() {
    super('live');
    this.liveAndSuspended = new Simple('live-and-suspended', 'state', MatchState.LIVE);
    this.notSuspended = new Simple('not-suspended', 'substate', MatchStatus.SUSPENDED, false);
  }

  /**
   * @inheritdoc
   */
  apply(data) {
    return this.liveAndSuspended.apply(this.notSuspended.apply(data));
  }
}
